import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../components/elements/BreadCrumb'
import AlertCustom from '../../components/elements/AlertCustom'
import Axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { FloatingLabel, Form, Modal, Table } from 'react-bootstrap'

const baseURL = process.env.REACT_APP_ENDPOINT

const TrackPapers = () => {
    //Define
    let tokenStr = localStorage.getItem('access_token')
    const navigate = useNavigate()
    const detailsId = useParams()
    const [data, setData] = useState({})
    const [allPaper, setAlPaper] = useState([])
    const [alertData, setAlertData] = useState({
        'alertShow': false,
        'alertVariant': null,
        'alertMessage': null
    })

    const [showReviewerModal, setShowReviewerModal] = useState(false);
    const [singleTrackReviewer, setSingleTrackReviewer] = useState([])
    const [singlePaperReviewer, setSinglePaperReviewer] = useState([])
    const [paperId, setPaperId] = useState()



    //Action
    useEffect(() => {
        if (alertData.alertShow) {
            setTimeout(() => {
                setAlertData({
                    'alertShow': false,
                })
            }, 7000)
        }
    }, [alertData])

    useEffect(() => {
        Axios.get(`${baseURL}/site/conf/short/${detailsId.id}`)
            .then(res => {
                // setData(res.data.result[0])
            })
            .catch(
                error => {
                    if (error.response.status === 401) {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'danger',
                            'alertMessage': 'You are not authorized to access this content.You will be logged out. Please login again!'
                        })
                        setTimeout(() => {
                            navigate('/')
                        }, 6000)
                    } else {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'warning',
                            'alertMessage': error.response.data
                        })
                    }
                }
            )

        getTrackPapers()
        getSingleTrack(detailsId.id)
    }, [])

    const getTrackPapers = () => {
        Axios.get(`${baseURL}/paper/all/${detailsId.id}/1`, { headers: { "Authorization": `Bearer ${tokenStr}` } })
            .then(res => {
                setAlPaper(res.data.result.data)
            })
            .catch(
                error => {
                    if (error.response.status === 401) {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'danger',
                            'alertMessage': 'You are not authorized to access this content.You will be logged out. Please login again!'
                        })
                        setTimeout(() => {
                            navigate('/')
                        }, 6000)
                    } else {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'warning',
                            'alertMessage': error.response.data
                        })
                    }
                }
            )
    }
    const getSingleTrackPapers = (paperId) => {
        Axios.get(`${baseURL}/paper/${paperId}`, { headers: { "Authorization": `Bearer ${tokenStr}` } })
            .then(res => {
                setSinglePaperReviewer(res.data.result.paper_reviewer_data)
            })
            .catch(
                error => {
                    if (error.response.status === 401) {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'danger',
                            'alertMessage': 'You are not authorized to access this content.You will be logged out. Please login again!'
                        })
                        setTimeout(() => {
                            navigate('/')
                        }, 6000)
                    } else {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'warning',
                            'alertMessage': error.response.data
                        })
                    }
                }
            )
    }
    const getSingleTrack = (id) => {
        Axios.get(`${baseURL}/track/${id}`, { headers: { "Authorization": `Bearer ${tokenStr}` } })
            .then(res => {
                setSingleTrackReviewer(res.data.result.track_reviewer_data)
            })
            .catch(
                error => {
                    if (error.response.status === 401) {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'danger',
                            'alertMessage': 'You are not authorized to access this content.You will be logged out. Please login again!'
                        })
                        setTimeout(() => {
                            navigate('/')
                        }, 6000)
                    } else {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'warning',
                            'alertMessage': error.response.data
                        })
                    }
                }
            )
    }

    const handleCloseReviewerModal = () => setShowReviewerModal(false);
    const handleShowReviewerModal = (id) => {
        setPaperId(id)
        getSingleTrackPapers(id)
        setShowReviewerModal(true)
    };

    const addReviewer = (paperId, reviewer_id) => {
        const confirmation = window.confirm("Are you sure to add this TPC Member as Reviewer?")
        if (confirmation) {
            Axios.post(`${baseURL}/paper/reviewer-assign/${paperId}`, { 'reviewer_id': reviewer_id }, { headers: { "Authorization": `Bearer ${tokenStr}` } })
                .then((res) => {
                    getTrackPapers()
                    getSingleTrackPapers(paperId)
                    if (res.data.success === false) {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'warning',
                            'alertMessage': res.data.message
                        })
                    } else {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'success',
                            'alertMessage': res.data.message
                        })
                    }

                })
                .catch(
                    error => {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'danger',
                            'alertMessage': error.response.data
                        })
                    }
                )
        }
    }
    const deleteReveiewer = (paper_id, id) => {
        const confirmation = window.confirm("Are you sure to delete this Reviewer?")
        if (confirmation) {
            Axios.delete(`${baseURL}/paper/reviewer/${id}`, { headers: { "Authorization": `Bearer ${tokenStr}` } })
                .then((res) => {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'success',
                        'alertMessage': res.data.message
                    })

                    getTrackPapers()
                    getSingleTrackPapers(paper_id)

                })
                .catch(
                    error => {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'danger',
                            'alertMessage': error.response.data
                        })
                    }
                )
        }
    }

    return (
        <>
            <AlertCustom data={alertData} />
            <BreadCrumb title={`All Papers - ${data.acronym_title}`} from='Conference' to='Track Papers' back={true} />
            <div className='card elevated p-3'>
                <Table striped bordered hover>
                    <thead>
                        <tr className=''>
                            <th>Sequence</th>
                            <th>Paper Title</th>
                            <th>Authors</th>
                            <th>Reviewers</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            allPaper.length > 0 ?
                                allPaper.map((item) => {
                                    const { id, paper_title, paper_sequence, paper_author_data, paper_reviewer_data } = item
                                    return (

                                        <tr>
                                            <td><span className='badge bg-primary'><small>{paper_sequence}</small></span></td>
                                            <td>{paper_title}</td>
                                            <td>
                                                {
                                                    paper_author_data.length > 0 ?
                                                        paper_author_data.map((item) => {
                                                            const { first_name, last_name, email } = item
                                                            return (
                                                                <div className='mb-3'>{first_name} {last_name} <br />{email}</div>
                                                            )
                                                        })
                                                        : ''
                                                }
                                            </td>
                                            <td>{
                                                paper_reviewer_data.length > 0 ?
                                                    paper_reviewer_data.map((item) => {
                                                        const { first_name, last_name, email } = item
                                                        return (
                                                            <div className='mb-3'>{first_name} {last_name} <br />{email}</div>
                                                        )
                                                    })
                                                    : ''
                                            }
                                            </td>
                                            <td>
                                                <button className='btn btn-light elevated btn-xs btn-classic mb-2 me-1' title='Manage Reviewers' onClick={() => handleShowReviewerModal(id)}><span className="material-icons text-warning">manage_accounts</span> Manage Reviewers</button>
                                            </td>
                                        </tr>
                                    )
                                }) : ''
                            // <div className='card bg-fill-light-redish'><div className='card-body'><span class='material-icons text-danger'>priority_high</span> No paper is submitted yet.</div></div>
                        }
                    </tbody>
                </Table>
            </div>

            {/* Manage Reviewer */}
            <Modal Modal show={showReviewerModal} onHide={handleCloseReviewerModal} size='lg' >
                <Modal.Header closeButton>
                    <Modal.Title>Manage Reviewer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-6" style={{ minHeight: '20rem' }}>
                            <h3><strong>TPC Members</strong></h3>

                            {
                                singleTrackReviewer.map((item) => {
                                    const { reviewer_id, reviewer_first_name, reviewer_last_name, reviewer_email } = item
                                    return (
                                        <div className='d-flex justify-content-between py-1 px-3 listItem'>
                                            <div>
                                                <h5 className='mb-1'>{reviewer_first_name} {reviewer_last_name}</h5>
                                                <span><small>{reviewer_email}</small></span>
                                            </div>
                                            <div>

                                                <button className='btn btn-sm btn-classic btn-primary' onClick={() => addReviewer(paperId, reviewer_id)}><span className="material-icons">add</span> Add</button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="col-6 border-start">
                            {
                                <>
                                    <h3><strong>Added Reviewers</strong></h3>
                                    <ul className='list-unstyled'>
                                        {
                                            singlePaperReviewer.map((item) => {
                                                const { paper_id, id, first_name, last_name, email } = item
                                                return (
                                                    <div className='d-flex justify-content-between align-items-center mb-1 listItem'>
                                                        <div>
                                                            <h5 className='m-0'>{first_name} {last_name} / <span><small>{email}</small></span></h5>
                                                        </div>
                                                        <div>
                                                            <button className='btn btn-xs btn-classic btn-danger' onClick={() => deleteReveiewer(paper_id, id)}><span className="material-icons">delete_forever</span> Remove</button>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </ul>
                                </>
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal >
        </>
    )
}

export default TrackPapers