import Axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import BreadCrumb from '../../components/elements/BreadCrumb'
import { Editor } from '@tinymce/tinymce-react';
import AlertCustom from '../../components/elements/AlertCustom';

import CountryList from '../../data/CountryList'
import organizingCommitteeType from '../../data/OrganizingCommitteeType'

const baseURL = process.env.REACT_APP_ENDPOINT
const TINYMCE = process.env.REACT_APP_TINYMCE_API_KEY


const Cfp = () => {
    ///Define
    const navigate = useNavigate()
    const detailsId = useParams()

    const [data, setData] = useState({
        "event_name": "",
        "acronym_title": "",
    })
    const [alertData, setAlertData] = useState({
        'alertShow': false,
        'alertVariant': null,
        'alertMessage': null
    })

    const [octData, setOctData] = useState([])
    const [rightData, setRightData] = useState([])

    let tokenStr = localStorage.getItem('access_token')

    const [searchText, setSearchText] = useState('')
    const [userList, setUserList] = useState([])
    const [userTempList, setUserTempList] = useState([])
    const [selectedCfpPersonValue, setSelectedCfpPersonValue] = useState('')
    ///Action
    useEffect(() => {
        if (alertData.alertShow) {
            setTimeout(() => {
                setAlertData({
                    'alertShow': false,
                    'alertVariant': '',
                    'alertMessage': null
                })
            }, 7000)
        }
    }, [alertData])

    useEffect(() => {

        if (detailsId.id > 0) {

            Axios.get(`${baseURL}/site/conf/short/${detailsId.id}`)
                .then(res => {
                    setData(res.data.result[0])
                })
                .catch(
                    error => {
                        if (error.response.status === 401) {
                            setAlertData({
                                'alertShow': true,
                                'alertVariant': 'danger',
                                'alertMessage': 'You are not authorized to access this content.You will be logged out. Please login again!'
                            })
                            setTimeout(() => {
                                navigate('/')
                            }, 6000)
                        } else {
                            setAlertData({
                                'alertShow': true,
                                'alertVariant': 'warning',
                                'alertMessage': error.response.data
                            })
                        }
                    }
                )
            Axios.get(`${baseURL}/cfp/${detailsId.id}`, { headers: { "Authorization": `Bearer ${tokenStr}` } })
                .then(res => {
                    setRightData(res.data.result)


                    const octLookup = organizingCommitteeType.reduce((acc, { value, label }) => {
                        acc[value] = label;
                        return acc;
                    }, {});

                    let cfp_data = res.data.result.cfp_data
                    //Merge with organizing_Committee_Type
                    const merged_label = cfp_data.map(item => ({
                        id: item.id,
                        organizing_committee_type: item.organizing_committee_type,
                        organizing_committee_type_label: octLookup[item.organizing_committee_type],
                        first_name: item.first_name,
                        last_name: item.last_name,
                        email: item.email,
                        sequence: item.sequence,
                        affiliation: item.affiliation,
                        country: item.country
                    }));

                    //Group by label
                    const grouped_label = Object.groupBy(merged_label, ({ organizing_committee_type_label }) => organizing_committee_type_label)

                    Object.keys(grouped_label).forEach(key => {
                        grouped_label[key].sort((a, b) => a.sequence - b.sequence);
                    });

                    const result = Object.entries(grouped_label).map(([key, value]) => ({
                        oct: key,
                        list: value.sort((a, b) => a.sequence - b.sequence)
                    }));

                    setOctData(result)

                })
                .catch(
                    error => {
                        if (error.response.status === 401) {
                            setAlertData({
                                'alertShow': true,
                                'alertVariant': 'danger',
                                'alertMessage': 'You are not authorized to access this content.You will be logged out. Please login again!'
                            })
                            setTimeout(() => {
                                navigate('/')
                            }, 6000)
                        } else {
                            setAlertData({
                                'alertShow': true,
                                'alertVariant': 'warning',
                                'alertMessage': error.response.data
                            })
                        }
                    }
                )

            Axios.get(`${baseURL}/users`, { headers: { "Authorization": `Bearer ${tokenStr}` } })
                .then(res => {
                    setUserTempList(res.data.result.data)
                })
                .catch(
                    error => {
                        if (error.response.status === 401) {
                            setAlertData({
                                'alertShow': true,
                                'alertVariant': 'danger',
                                'alertMessage': 'You are not authorized to access this content.You will be logged out. Please login again!'
                            })
                            setTimeout(() => {
                                navigate('/')
                            }, 6000)
                        } else {
                            setAlertData({
                                'alertShow': true,
                                'alertVariant': 'warning',
                                'alertMessage': error.response.data
                            })
                        }
                    }
                )


        }
    }, [detailsId])


    const editorRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const submitCFPDetails = (e) => {
        e.preventDefault()
        let rte = ''
        if (editorRef.current) {
            rte = editorRef.current.getContent()
        }

        let formdata = {
            'cfp_details': rte,
            'cfp_file': selectedFile
        }

        const confirmation = window.confirm("Are you sure to update the CFP details?")
        if (confirmation) {
            Axios.post(`${baseURL}/cfp-data/update/${detailsId.id}`, formdata, { headers: { "Authorization": `Bearer ${tokenStr}`, "Content-type": "multipart/form-data" } })
                .then((res) => {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'success',
                        'alertMessage': res.data.message
                    })
                    window.location.reload()
                })
                .catch(
                    error => {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'danger',
                            'alertMessage': error.response.data
                        })
                    }
                )
        }
    }

    const [octFormData, setOctFormData] = useState({
        'organizing_committee_type': 1,
        'first_name': '',
        'last_name': '',
        'email': '',
        'country': '',
        'affiliation': '',
        'sequence': 1,
    })
    const [octFormDataSearch, setOctFormDataSearch] = useState({
        'organizing_committee_type_search': 1,
        'cfp_person_search': '',
        'sequence_search': 1,
    })

    const handleChangeOct = (e) => {
        let name = e.target.name
        let value = e.target.value

        setOctFormData((prev) => {
            return {
                ...prev, [name]: value
            }
        })
    }
    const submitOCT = (e) => {
        e.preventDefault()
        let name = e.target.name
        let value = e.target.value

        setOctFormData((prev) => {
            return {
                ...prev, [name]: value
            }
        })

        Axios.post(`${baseURL}/cfp/${detailsId.id}`, octFormData, { headers: { "Authorization": `Bearer ${tokenStr}` } })
            .then((res) => {
                setAlertData({
                    'alertShow': true,
                    'alertVariant': 'success',
                    'alertMessage': res.data.message
                })
                window.location.reload()
            })
            .catch(
                error => {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'danger',
                        'alertMessage': error.response.data
                    })
                }
            )
    }


    const selectCfpUser = (id, first_name, last_name) => {
        setSelectedCfpPersonValue(id)
        setSearchText(first_name + last_name)
        setUserList([])
    }
    const handleChangeOctSearch = (e) => {
        let name = e.target.name
        let value = e.target.value

        setOctFormDataSearch((prev) => {
            return {
                ...prev, [name]: value
            }
        })
        octFormDataSearch.cfp_person_search = selectedCfpPersonValue

    }

    const submitOCTSearchType = (e) => {
        e.preventDefault()
        let name = e.target.name
        let value = e.target.value

        setOctFormDataSearch((prev) => {
            return {
                ...prev, [name]: value
            }
        })

        octFormDataSearch.cfp_person_search = selectedCfpPersonValue

        let ofds = {
            organizing_committee_type: octFormDataSearch.organizing_committee_type_search,
            sequence: octFormDataSearch.sequence_search,
            org_com_user_id: selectedCfpPersonValue
        }

        Axios.post(`${baseURL}/cfp/${detailsId.id}`, ofds, { headers: { "Authorization": `Bearer ${tokenStr}` } })
            .then((res) => {
                setAlertData({
                    'alertShow': true,
                    'alertVariant': 'success',
                    'alertMessage': res.data.message
                })
                window.location.reload()
            })
            .catch(
                error => {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'danger',
                        'alertMessage': error.response.data
                    })
                }
            )
    }

    const deleteOct = (id) => {
        const confirmation = window.confirm("Are you sure to delete this Organizing Committee?")
        if (confirmation) {
            Axios.delete(`${baseURL}/cfp/${id}`, { headers: { "Authorization": `Bearer ${tokenStr}` } })
                .then((res) => {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'success',
                        'alertMessage': res.data.message
                    })
                    window.location.reload()
                })
                .catch(
                    error => {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'danger',
                            'alertMessage': error.response.data
                        })
                    }
                )
        }
    }

    const loadCfpPerson = (e) => {
        setSearchText(e.target.value)

        if (searchText.length > 1) {
            setUserList(userTempList.filter((item) => {
                item.email = item.email.toLocaleLowerCase()
                item.first_name = item.first_name.toLocaleLowerCase()
                item.last_name = item.last_name.toLocaleLowerCase()
                const compareString = searchText.toLocaleLowerCase()
                return item.email.startsWith(compareString) || item.first_name.startsWith(compareString) || item.last_name.startsWith(compareString)
            }))
        } else {
            setUserList([])
        }
    }

    return (
        <>
            <AlertCustom data={alertData} />
            <BreadCrumb title={`${data.acronym_title} CFP`} from='Conference' to='Manage CFP' back={true} />

            <div className='card elevated'>
                <div className="card-body">
                    <div className='row'>
                        <div className='col-5 border-end fh-cal-11'>
                            <h2>Organizing Committee</h2>
                            <hr />
                            <form onSubmit={submitOCTSearchType}>
                                <div className='row mb-3'>
                                    <div className='mb-2 col-md-6'>
                                        <label>Type</label>
                                        <select name='organizing_committee_type_search' className='form-select' onChange={handleChangeOctSearch}>
                                            {
                                                organizingCommitteeType.map((item) => {
                                                    const { value, label } = item
                                                    return (
                                                        <option value={value}>{label}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className='mb-2 col-md-6 search'>
                                        <label>Select User</label>
                                        <input autoComplete='off' className='form-control' type="text" placeholder="Search User" value={searchText} onChange={loadCfpPerson} required />

                                        <div className='suggestionList elevated'>
                                            {
                                                userList.map((item) => {
                                                    const { id, first_name, last_name, email } = item
                                                    return (
                                                        <div className='d-block cursor-pointer hover p-1 listItem' onClick={() => selectCfpUser(id, first_name, last_name)}>
                                                            <h5 className='d-block mb-1'>{first_name} {last_name}</h5>
                                                            <span><small>{email}</small></span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className='mb-2 col-md-6'>
                                        <label>Sequence</label>
                                        <input type="number" min={1} name="sequence_search" className='form-control' onChange={handleChangeOctSearch} />
                                    </div>
                                    <div className='mb-2 col-md-6'>
                                        <button type="submit" className='btn btn-primary margin-label'><span className="material-icons">add</span> Add</button>
                                    </div>
                                </div>
                            </form>
                            <form onSubmit={submitOCT}>
                                <div class="text-center bg-fill-light-gray p-3 mb-2 rounded"><h3>OR</h3>If not found you can add an User manually here.</div>

                                <div className='row mb-3'>
                                    <div className='mb-2 col-md-6'>
                                        <label>Type</label>
                                        <select name='organizing_committee_type' className='form-select' onChange={handleChangeOct}>
                                            {
                                                organizingCommitteeType.map((item) => {
                                                    const { value, label } = item
                                                    return (
                                                        <option value={value}>{label}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className='mb-2 col-md-6'>
                                        <label>First Name</label>
                                        <input type="text" name="first_name" className='form-control' onChange={handleChangeOct} required />
                                    </div>
                                    <div className='mb-2 col-md-6'>
                                        <label>Last Name</label>
                                        <input type="text" name="last_name" className='form-control' onChange={handleChangeOct} required />
                                    </div>
                                    <div className='mb-2 col-md-6'>
                                        <label>Email</label>
                                        <input type="email" name="email" className='form-control' onChange={handleChangeOct} required />
                                    </div>
                                    <div className='mb-2 col-md-6'>
                                        <label>Affiliation</label>
                                        <input type="text" name="affiliation" className='form-control' onChange={handleChangeOct} required />
                                    </div>
                                    <div className='mb-2 col-md-6'>
                                        <label>Country</label>
                                        <select name="country" className='form-select' onChange={handleChangeOct} required>
                                            {
                                                CountryList.map((item, key) => {
                                                    return <option value={item} key={key}>{item}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className='mb-2 col-md-6'>
                                        <label>Sequence</label>
                                        <input type="number" min={1} name="sequence" className='form-control' onChange={handleChangeOct} />
                                    </div>
                                    <div className='mb-2 col-md-6'>
                                        <button type="submit" className='btn btn-primary margin-label'><span className="material-icons">add</span> Add</button>
                                    </div>
                                </div>


                                {/* <div className='row mb-3'>
                                    <div className='mb-2 col-md-6'>
                                        <label>Type</label>
                                        <select name='organizing_committee_type' className='form-select' onChange={handleChangeOct}>
                                            {
                                                organizingCommitteeType.map((item) => {
                                                    const { value, label } = item
                                                    return (
                                                        <option value={value}>{label}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className='mb-2 col-md-6'>
                                        <label>Name</label>
                                        <input type="text" name="name" className='form-control' onChange={handleChangeOct} required />
                                    </div>
                                    <div className='mb-2 col-md-4'>
                                        <label>Affiliation</label>
                                        <input type="text" name="affiliation" className='form-control' onChange={handleChangeOct} required />
                                    </div>
                                    <div className='mb-2 col-md-3'>
                                        <label>Country</label>
                                        <select name="country" className='form-select' onChange={handleChangeOct} required>
                                        {
                                            CountryList.map((item, key) => {
                                                return <option value={item} key={key}>{item}</option>
                                            })
                                        }
                                        </select>                                        
                                    </div>
                                    <div className='mb-2 col-md-2'>
                                        <label>Sequence</label>
                                        <input type="number" min={1} name="sequence" className='form-control' onChange={handleChangeOct} />
                                    </div>
                                    <div className='mb-2 col-md-3 text-end'>
                                        <button type="submit" className='btn btn-sm btn-success w-100 mt-2'><span className="material-icons">add</span> Add</button>
                                    </div>
                                </div> */}
                            </form>

                            {
                                octData.map((item) => {
                                    const { oct, list } = item
                                    return (
                                        <>
                                            <h4><strong>{oct}</strong></h4>
                                            <ul className='list-unstyled'>
                                                {
                                                    list.map((item) => {
                                                        const { id, first_name, last_name, affiliation, country } = item
                                                        return (
                                                            <>
                                                                <div className='d-flex justify-content-between mb-2'>
                                                                    <div className='col'>
                                                                        {first_name} {last_name} <br />
                                                                        <small>{affiliation}, {country}</small>
                                                                    </div>
                                                                    <div>
                                                                        <button className='btn btn-danger btn-xs' onClick={() => deleteOct(id)}><span className="material-icons">delete</span> Delete</button>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </>
                                    )
                                })
                            }

                        </div>
                        <div className='col-7 fh-cal-11'>
                            <h2>Call for Papers</h2>
                            <hr />
                            <form onSubmit={submitCFPDetails}>
                                <div className='mb-3'>
                                    <label htmlFor="cfp_details">Paper Information</label>
                                    <Editor
                                        apiKey={TINYMCE}
                                        onInit={(evt, editor) => editorRef.current = editor}
                                        initialValue={rightData.cfp_details}
                                        init={{
                                            height: 400,
                                            menubar: true,
                                            plugins: [
                                                'advlist', 'autolink', 'lists', 'link', 'charmap', 'preview',
                                                'searchreplace', 'visualblocks', 'fullscreen',
                                                'table', 'help', 'wordcount'
                                            ],
                                            toolbar: 'undo redo | blocks | ' +
                                                'bold italic forecolor | alignleft aligncenter ' +
                                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                                'removeformat | help',
                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                        }}
                                    />

                                </div>
                                <div className='col mb-3'>
                                    <label htmlFor="cfp_file">Upload PDF</label>
                                    <input type="file" name="cfp_file" id="cfp_file" className='form-control' onChange={handleFileChange} />
                                </div>
                                <div className='mb-2 d-flex justify-content-between text-end'>
                                    {
                                        rightData.cfp_file ? <a className='btn btn-primary btn-sm' href={`data:${rightData.cfp_file_mime_type};base64,${rightData.cfp_file}`} download={data.conf_id}><span className="material-icons">file_download</span> Download CFP</a> : ''
                                    }
                                    <button type='submit' className='btn btn-sm btn-large btn-primary'><span className="material-icons">save</span> Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Cfp