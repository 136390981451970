import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../components/elements/BreadCrumb'
import AlertCustom from '../../components/elements/AlertCustom'
import Axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { Card, FloatingLabel, Form, Modal, Table } from 'react-bootstrap'
import ReviewerEvaluation from '../../data/ReviewerEvaluation'
import ReviewerConfidence from '../../data/ReviewerConfidence'

const baseURL = process.env.REACT_APP_ENDPOINT

const ReviewPapers = () => {
    //Define
    let tokenStr = localStorage.getItem('access_token')
    let currentEmail = localStorage.getItem('email')
    let currentRole = localStorage.getItem('role')
    const navigate = useNavigate()
    const detailsId = useParams()
    const [data, setData] = useState({})
    const [allPaper, setAlPaper] = useState([])
    const [alertData, setAlertData] = useState({
        'alertShow': false,
        'alertVariant': null,
        'alertMessage': null
    })

    const [showReviewModal, setShowReviewModal] = useState(false)
    const [dataReview, setDataReview] = useState({
        'reviewer_evaluation': 'Strong Accept (3)',
        'reviewer_confidence': 'Expert (5)',
        'reviewer_remarks': '',
        'reviewer_confidential_remarks': ''
    })
    const [paperId, setPaperId] = useState(null)
    const [singlePaper, setSinglePaper] = useState([])



    //Action
    useEffect(() => {
        if (alertData.alertShow) {
            setTimeout(() => {
                setAlertData({
                    'alertShow': false,
                })
            }, 7000)
        }
    }, [alertData])

    useEffect(() => {
        Axios.get(`${baseURL}/site/conf/short/${detailsId.id}`)
            .then(res => {
                setData(res.data.result[0])
            })
            .catch(
                error => {
                    if (error.response.status === 401) {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'danger',
                            'alertMessage': 'You are not authorized to access this content.You will be logged out. Please login again!'
                        })
                        setTimeout(() => {
                            navigate('/')
                        }, 6000)
                    } else {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'warning',
                            'alertMessage': error.response.data
                        })
                    }
                }
            )

        Axios.get(`${baseURL}/paper/all/${detailsId.id}/0`, { headers: { "Authorization": `Bearer ${tokenStr}` } })
            .then(res => {
                setAlPaper(res.data.result.data)
            })
            .catch(
                error => {
                    if (error.response.status === 401) {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'danger',
                            'alertMessage': 'You are not authorized to access this content.You will be logged out. Please login again!'
                        })
                        setTimeout(() => {
                            navigate('/')
                        }, 6000)
                    } else {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'warning',
                            'alertMessage': error.response.data
                        })
                    }
                }
            )
    }, [])
    const getSinglePaper = (id) => {
        Axios.get(`${baseURL}/paper/${id}`, { headers: { "Authorization": `Bearer ${tokenStr}` } })
            .then(res => {
                setSinglePaper(res.data.result)

                const filterPrevReviewData = () => {
                    return (
                        res.data.result.paper_reviewer_data.filter((items) => {
                            return items.email === currentEmail
                        })
                    )
                }
                const prevReviewData = filterPrevReviewData()

                if (prevReviewData[0].reviewer_evaluation != null) {
                    setDataReview({
                        'reviewer_evaluation': prevReviewData[0].reviewer_evaluation,
                        'reviewer_confidence': prevReviewData[0].reviewer_confidence,
                        'reviewer_remarks': prevReviewData[0].reviewer_remarks,
                        'reviewer_confidential_remarks': prevReviewData[0].reviewer_confidential_remarks
                    })
                }

            })
            .catch(
                error => {
                    console.log(error)
                    // if (error.response.status === 401) {
                    //     setAlertData({
                    //         'alertShow': true,
                    //         'alertVariant': 'danger',
                    //         'alertMessage': 'You are not authorized to access this content.You will be logged out. Please login again!'
                    //     })
                    //     setTimeout(() => {
                    //         navigate('/')
                    //     }, 6000)
                    // } else {
                    //     setAlertData({
                    //         'alertShow': true,
                    //         'alertVariant': 'warning',
                    //         'alertMessage': error.response.data
                    //     })
                    // }
                }
            )
    }

    const handleCloseReviewModal = () => setShowReviewModal(false);
    const handleShowReviewModal = (id) => {
        setPaperId(id)
        getSinglePaper(id)

        setShowReviewModal(true)
    };

    const handleChangeReview = (e) => {
        let name = e.target.name
        let value = e.target.value

        setDataReview((prev) => {
            return {
                ...prev, [name]: value
            }
        })
    }

    const handleSubmitReview = (e) => {
        e.preventDefault()
        let name = e.target.name
        let value = e.target.value

        setDataReview((prev) => {
            return {
                ...prev, [name]: value
            }
        })

        const confirmation = window.confirm(`Are you sure to Submit this Review for : ${singlePaper.paper_sequence} - ${singlePaper.paper_title}`)
        if (confirmation) {
            // console.log(dataReview)
            Axios.post(`${baseURL}/paper/add-review/${paperId}`, dataReview, { headers: { "Authorization": `Bearer ${tokenStr}` } })
                .then((res) => {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'success',
                        'alertMessage': res.data.message
                    })
                    window.location.reload()
                })
                .catch(
                    error => {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'danger',
                            'alertMessage': error.response.data
                        })
                    }
                )
        }
    }

    return (
        <>
            <AlertCustom data={alertData} />
            <BreadCrumb title={`All Papers - ${data.acronym_title}`} from='Conference' to='Papers' back={true} />

            <Card className='elevated p-4'>
                <Table striped bordered hover>
                    <thead>
                        <tr className=''>
                            <th>PaperID</th>
                            <th>Download</th>
                            <th>Paper Title</th>
                            <th>Authors</th>
                            <th>Track</th>
                            <th>Review</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            allPaper.length > 0 ?
                                allPaper.map((item) => {
                                    const { id, paper_title, paper_sequence, paper_file, paper_file_mime_type, paper_author_data, track_name, paper_reviewer_data } = item

                                    let reviewerChecker = () => {
                                        return (
                                            paper_reviewer_data.filter((items) => {
                                                return items.email === currentEmail
                                            })
                                        )
                                    }

                                    return (
                                        // reviewerChecker().length > 0 || currentRole === "ADMIN" ?
                                        <tr>
                                            <td><span className='badge bg-primary'><small>{paper_sequence}</small></span></td>
                                            <td>
                                                {
                                                    paper_file ? <a className='btn btn-primary btn-xs' href={`data:${paper_file_mime_type};base64,${paper_file}`} download={paper_sequence}><span className="material-icons">file_download</span> Download</a> : ''
                                                }
                                            </td>
                                            <td>{paper_title}</td>
                                            <td>
                                                {
                                                    paper_author_data.length > 0 ?
                                                        paper_author_data.map((item) => {
                                                            const { first_name, last_name, email } = item
                                                            return (
                                                                <div className='mb-3'>{first_name} {last_name} <br />{email}</div>
                                                            )
                                                        })
                                                        : ''
                                                }
                                            </td>
                                            <td>{track_name}</td>
                                            <td>

                                                <button className='btn btn-light elevated btn-xs btn-classic mb-2 me-1' onClick={() => handleShowReviewModal(id)} title='Review'><span className="material-icons text-warning">reviews</span> Subbmit Review</button>
                                            </td>
                                        </tr>
                                        // : ''
                                    )
                                }) : ''
                            // <div className='card bg-fill-light-redish'><div className='card-body'><span class='material-icons text-danger'>priority_high</span> No paper is submitted yet.</div></div>
                        }
                    </tbody>
                </Table>
            </Card>
            <Modal show={showReviewModal} onHide={handleCloseReviewModal} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title><span className='badge bg-primary'>{singlePaper.paper_sequence}</span> {singlePaper.paper_title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <Form onSubmit={handleSubmitReview}>
                            <div className="mb-3">
                                <div className="row">
                                    <div className="col">
                                        <Form.Group className='' controlId="" label="Reviewer Evaluation" >
                                            <Form.Label>Reviewer Evaluation</Form.Label>
                                            <Form.Select name='reviewer_evaluation' value={dataReview.reviewer_evaluation} onChange={handleChangeReview}>
                                                {
                                                    ReviewerEvaluation.map((item) => {
                                                        const { value, label } = item
                                                        return <option value={value}>{label}</option>
                                                    })
                                                }
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                    <div className="col">
                                        <Form.Group className='' controlId="" label="Reviewer Confidence" >
                                            <Form.Label>Reviewer Confidence</Form.Label>
                                            <Form.Select name='reviewer_confidence' value={dataReview.reviewer_confidence} onChange={handleChangeReview}>
                                                {
                                                    ReviewerConfidence.map((item) => {
                                                        const { value, label } = item
                                                        return <option value={value}>{label}</option>
                                                    })
                                                }
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                            <div className='mb-3'>
                                <Form.Group controlId="reviewer_remarks" label="Remarks" className='required'>
                                    <Form.Label>Remarks</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        placeholder="Your Remark"
                                        name='reviewer_remarks'
                                        value={dataReview.reviewer_remarks}
                                        onChange={handleChangeReview}
                                        style={{ height: '100px' }}
                                        required={true}
                                    />
                                </Form.Group>
                            </div>
                            <div className='mb-3'>
                                <Form.Group controlId="reviewer_confidential_remarks" label="Confidential Remarks">
                                    <Form.Label>Confidential Remarks</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        placeholder="Any Confidential Remarks"
                                        name='reviewer_confidential_remarks'
                                        value={dataReview.reviewer_confidential_remarks}
                                        onChange={handleChangeReview}
                                        style={{ height: '100px' }}
                                    />
                                </Form.Group>
                            </div>

                            <div className='row'>
                                <div className='col text-end'>
                                    <button className="btn btn-clear secondary ms-3" onClick={handleCloseReviewModal} type='reset'>Cancel</button>
                                    <button className="btn btn-clear priamry" type='submit'>Submit</button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ReviewPapers