import loginLogo from '../../static/images/logo-180-50.png'
import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Card, CardBody, FloatingLabel, Form, InputGroup } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import AlertCustom from '../../components/elements/AlertCustom';
import styles from './login.module.css';
import {useUserContext} from "../../contexts/UserContext";


const baseURL = process.env.REACT_APP_ENDPOINT

const Login = () => {

    const [userContext, dispatchContext] = useUserContext();
    const navigate = useNavigate()
    const [alertData, setAlertData] = useState({
        'alertShow': false,
        'alertVariant': null,
        'alertMessage': null
    })
    const [data, setData] = useState({
        email: '',
        password: ''
    })

    const handleChange = (e) => {
        setData((prev) => {
            const name = e.target.name
            const value = e.target.value
            return {
                ...prev, [name]: value
            }
        })
    }

    useEffect(() => {
        if (alertData.alertShow) {
            setTimeout(() => {
                setAlertData({
                    'alertShow': false,
                    'alertVariant': '',
                    'alertMessage': null
                })
            }, 7000)
        }
    }, [alertData])

    const formSubmit = (e) => {
        e.preventDefault()

        setData((prev) => {
            const name = e.target.name
            const value = e.target.value
            return {
                ...prev, [name]: value
            }
        })


        Axios.post(`${baseURL}/login`, data)
            .then((res) => {

                dispatchContext({ type: "onUserLogin", payload: res.data.user });
                return (
                    localStorage.setItem('access_token', res.data.access_token),
                    localStorage.setItem('first_name', res.data.user.first_name),
                    localStorage.setItem('last_name', res.data.user.last_name),
                    localStorage.setItem('email', res.data.user.email),
                    localStorage.setItem('id', res.data.user.id),
                    localStorage.setItem('role', res.data.user.role),
                    // navigate('/dashboard')
                    navigate('/conference')
                )
            })
            .catch(
                error => {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'danger',
                        'alertMessage': error.response.data.message
                    })
                }
            )

    }

    return (
        <>
            <AlertCustom data={alertData} />
            <div className={styles.loginPage}>
                <div className={styles.leftPanel}>
                    <div>
                        <svg width="301" height="77" viewBox="0 0 301 77" xmlns="http://www.w3.org/2000/svg">
                            <text id="EASYMEET"   x="3" y="74" font-family="Impact" font-size="74" font-weight="800" fill="#ffffff">EASYMEET</text>
                        </svg>
                        <div className={styles.leftPanelMessage}>The easiest platform for creating conferences.</div>
                    </div>

                        <img className={styles.leftPanelOverlayImg} src="/images/1206.jpg" alt={''} />


                </div>
                <div className={styles.rightPanel}>
                    <div className='d-flex justify-content-center align-items-center login'>
                        <Card className='login-card '>
                            <CardBody>
                                <Form onSubmit={formSubmit}>

                                    <h1>Login to manage conferences</h1>
                                    <InputGroup className="mb-2 mt-4">
                                        <InputGroup.Text id="basic-addon1"><span className="material-icons">person_outline</span></InputGroup.Text>
                                        <FloatingLabel controlId="email" label="Email">
                                            <Form.Control type="email" name='email' placeholder="Email" value={data.email} onChange={handleChange} required />
                                            <span></span>
                                        </FloatingLabel>
                                    </InputGroup>
                                    <InputGroup className="mb-2">
                                        <InputGroup.Text id="basic-addon1"><span className="material-icons">lock_outline</span></InputGroup.Text>
                                        <FloatingLabel controlId="password" label="Password" >
                                            <Form.Control type="password" name='password' placeholder="Password" value={data.password} onChange={handleChange} required />
                                            <span></span>
                                        </FloatingLabel>
                                    </InputGroup>
                                    <input type="submit" value="Login" className='btn btn-primary w-100 my-3' />

                                    <p className='my-3 text-center'>Don't have an account? <Link to={'/signup'}>Signup</Link></p>
                                    <div className='mt-2 text-center'>
                                        <Link to={'/forgotpassword'}>Forgot Password ?</Link>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login