export const menuItems  = [
    // {
    //     'title': 'Dashboard',
    //     'url':'/dashboard',
    //     'icon':'dashboard'
    // },
    {
        'title': 'Conference',
        'url':'/conference',
        'icon':'ballot',
        'submenu':[
            {
                'title': 'All Conference',
                'url':'/conference/allConference',
            },
            {
                'title': 'Add Conference',
                'url':'/conference/addConference',
            },
        ]
    },{
        'title': 'Settings',
        'url':'/settings',
        'icon':'settings_applications',
        'submenu':[
            {
                'title': 'Users',
                'url':'/settings/manageUsers',
            },
            // {
            //     'title': 'Roles',
            //     'url':'/settings/manageUserGroups',
            // },
            // {
            //     'title': 'Permissions',
            //     'url':'/settings/managePermissions',
            // },
            // {
            //     'title': 'Role Permissions',
            //     'url':'/settings/manageGroupPermissions',
            // }
        ]
    },{
        'title': 'Profile',
        'url':'/user',
        'icon':'account_circle',
        'submenu':[
            {
                'title': 'Details',
                'url':'/user/profile',
            },
            {
                'title': 'Change Password',
                'url':'/user/changePassword',
            },
            // {
            //     'title': 'Logout',
            //     'url':'/settings/managePermissions',
            // }
        ]
    }
]