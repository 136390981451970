import React, {useEffect, useState} from 'react'
import BreadCrumb from '../../components/elements/BreadCrumb'
import {Link, useParams} from 'react-router-dom'
import Axios from 'axios'
import {Card, Container, FloatingLabel, Form, Modal} from 'react-bootstrap'

import CountryList from '../../data/CountryList'
import useAjax from "../../useAjax";
import {useApplicationContext} from "../../contexts/ApplicationContext";

const baseURL = process.env.REACT_APP_ENDPOINT

const MyPapers = () => {
    let currentID = localStorage.getItem('id')
    let currentEmail = localStorage.getItem('email')

    const detailsId = useParams()
    const [showAddPaperModal, setShowAddPaperModal] = useState(false);
    const [showEditPaperModal, setShowEditPaperModal] = useState(false);
    const [data, setData] = useState({})
    const [searchText, setSearchText] = useState('')
    const [userTempList, setUserTempList] = useState([])
    const [userList, setUserList] = useState([])
    const [paperId, setPaperId] = useState(null)
    const [, dispatchApplicationContext] = useApplicationContext();

    const [dataPaperAdd, setDataPaperAdd] = useState({
        conf_id: detailsId.id,
        track_id: '',
        paper_title: '',
        keywords: '',
        abstract: ''
    })

    const [authorAddManual, setAuthorAddManual] = useState({
        first_name: '',
        last_name: '',
        email: '',
        country: '',
        affiliation: ''
    })

    const [dataPaperEdit, setDataPaperEdit] = useState({
        paper_id: detailsId.id,
        track_id: '',
        paper_title: '',
        keywords: '',
        abstract: ''
    })

    const [trackList, setTrackList] = useState([])
    const [allPaper, setAlPaper] = useState([])

    const [showManageAuthorModal, setShowManageAuthorModal] = useState(false)
    const [singlePaperAuthor, setSinglePaperAuthor] = useState([])

    const handleShowAddPaperModal = () => setShowAddPaperModal(true);
    const handleCloseAddPaperModal = () => setShowAddPaperModal(false);


    const handleShowEditPaperModal = (id) => {
        Axios.get(`${baseURL}/paper/${id}`,)
            .then(res => {
                setDataPaperEdit({
                    paper_id: res.data.result.id,
                    track_id: res.data.result.track_id,
                    paper_title: res.data.result.paper_title,
                    keywords: res.data.result.keywords,
                    abstract: res.data.result.abstract
                })

            })

        setShowEditPaperModal(true)
    };
    const handleCloseEditPaperModal = () => setShowEditPaperModal(false);

    const [getData, getDataResponse] = useAjax('get');
    const [getTrackList, getTrackListResponse] = useAjax('get');
    const [getUserTempList, getUserTempListResponse] = useAjax('get');

    useEffect(() => {
        getData({
            URL: `${baseURL}/site/conf/short/${detailsId.id}`
        });
        getTrackList({
            URL: `${baseURL}/track/all/${detailsId.id}`
        });

        getUserTempList({
            URL: `${baseURL}/users`
        });
        getAllPapers()
    }, [])

    useEffect(()=>{
        if(getDataResponse){
            setData(getDataResponse.result[0])
        }
    },[getDataResponse]);

    useEffect(()=>{
        if(getTrackListResponse){
            setTrackList(getTrackListResponse.result)
        }
    },[getTrackListResponse]);

    useEffect(()=>{
        if(getUserTempListResponse){
            setUserTempList(getUserTempListResponse.result.data)
        }
    },[getUserTempListResponse]);

    const [getAllPaperCall, allPaperCallResponse] = useAjax('get');

    const getAllPapers = () => {
        getAllPaperCall({
            URL: `${baseURL}/paper/all/${detailsId.id}/0`
        });
    }
    useEffect(() => {
        if (allPaperCallResponse) {
            setAlPaper(allPaperCallResponse.result.data)
        }
    }, [allPaperCallResponse])


    const handleChangePaperAdd = (e) => {
        let name = e.target.name
        let value = e.target.value

        setDataPaperAdd((prev) => {
            return {
                ...prev, [name]: value
            }
        })
    }
    const handleChangePaperEdit = (e) => {
        let name = e.target.name
        let value = e.target.value

        setDataPaperEdit((prev) => {
            return {
                ...prev, [name]: value
            }
        })
    }

    const [dataFiles, setDataFiles] = useState({
        "paper_file": null
    })
    const handleChangeFile = (e) => {
        let name = e.target.name
        let value = e.target.files[0]

        setDataFiles((prev) => {
            return {
                ...prev, [name]: value
            }
        })
    }

    const [submitAddPaperCall, submitAddPaperResponse] = useAjax('post', `${baseURL}/paper/`);

    const handleSubmitAddPaper = (e) => {
        e.preventDefault()
        let formData = {
            ...dataPaperAdd,
            ...dataFiles
        }
        submitAddPaperCall({payload: formData, headers: {"Content-type": "multipart/form-data"}});
    }


    const [submitEditPaperCall, submitEditPaperResponse] = useAjax('post', `${baseURL}/paper/update`)
    const handleSubmitEditPaper = (e) => {
        e.preventDefault()

        const formData = {
            ...dataPaperEdit,
            ...dataFiles
        }
        submitEditPaperCall({payload: formData, headers: {"Content-type": "multipart/form-data"}});
    }

    const [deletePaperCall, deletePaperResponse] = useAjax('delete');
    const handleDeletePaper = (id) => {
        const confirmation = window.confirm("Are you sure to delete this Paper?")
        if (confirmation) {
            deletePaperCall({URL: `${baseURL}/paper/${id}`})
        }
    }

    useEffect(() => {
        if (submitAddPaperResponse || submitEditPaperResponse || deletePaperResponse) {
            window.location.reload();
        }
    }, [submitAddPaperResponse, submitEditPaperResponse, deletePaperResponse]);

    const handleShowManageAuthorModal = (id) => {
        getSinglePaper(id)
        setPaperId(id)

        setAuthorAddManual({
            first_name: '',
            last_name: '',
            email: '',
            country: '',
            affiliation: ''
        })
        setShowManageAuthorModal(true)
    };
    const handleCloseManageAuthorModal = () => setShowManageAuthorModal(false);

    const loadAuthor = (e) => {
        setSearchText(e.target.value)

        if (searchText.length > 1) {
            setUserList(userTempList.filter((item) => {
                item.email = item.email.toLocaleLowerCase()
                item.first_name = item.first_name.toLocaleLowerCase()
                item.last_name = item.last_name.toLocaleLowerCase()
                const compareString = searchText.toLocaleLowerCase()
                return item.email.startsWith(compareString) || item.first_name.startsWith(compareString) || item.last_name.startsWith(compareString)
            }))
        } else {
            setUserList([])
        }
    }

    const [getSinglePaperCall, singlePaperCallData] = useAjax('get');

    const getSinglePaper = (id) => {
        getSinglePaperCall({URL: `${baseURL}/paper/${id}`});
    }

    useEffect(() => {
        if (singlePaperCallData) {
            setSinglePaperAuthor(singlePaperCallData.result.paper_author_data)
        }
    }, [singlePaperCallData]);


    const [addAuthorCall, addAuthorResponse] = useAjax('post');


    const addAuthor = (paper_id, author_id) => {
        let addAuthorData = {
            author_id: author_id,
            country: '',
            affiliation: ''
        }
        addAuthorCall({URL: `${baseURL}/paper/author-assign/${paper_id}`, payload: addAuthorData})
    }
    useEffect(() => {
        if (addAuthorResponse) {

            dispatchApplicationContext({type: "serviceSuccess", payload: {statusMessage: addAuthorResponse?.message}})
            // do we need this?
            // getSinglePaper(paper_id)
            getAllPapers();
        }
    }, [addAuthorResponse]);

    const [deleteAuthorCall, deleteAuthorResponse] = useAjax('delete');

    const deleteAuthor = (paper_id, id) => {
        const confirmation = window.confirm("Are you sure to delete this author?")
        if (confirmation) {
            deletePaperCall({URL: `${baseURL}/paper/author/${id}`})
        }
    }

    useEffect(() => {
        if (deleteAuthorResponse) {
            dispatchApplicationContext({
                type: "serviceSuccess",
                payload: {statusMessage: deleteAuthorResponse?.message}
            })
            // do we need this?
            // getSinglePaper(paper_id)
            getAllPapers();
        }
    }, [deleteAuthorResponse])

    const handleChangeAuthorAddManual = (e) => {
        let name = e.target.name
        let value = e.target.value

        setAuthorAddManual((prev) => {
            return {
                ...prev, [name]: value
            }
        })
    }

    const [submitAuthorAddManualCall, submitAuthorAddManualResponse] = useAjax('post')

    const handleSubmitAuthorAddManual = (e) => {
        e.preventDefault()
        submitAuthorAddManualCall({
            URL: `${baseURL}/paper/author-assign/${paperId}`,
            payload: authorAddManual
        })
    }
    useEffect(() => {

        if (submitAuthorAddManualResponse) {
            dispatchApplicationContext({
                type: "serviceSuccess",
                payload: {statusMessage: submitAuthorAddManualResponse?.message}
            })
            setAuthorAddManual({
                first_name: '',
                last_name: '',
                email: '',
                country: '',
                affiliation: ''
            })
            // TODO : is this necessary?
            //getSinglePaper(paperId)
        }
    }, [submitAuthorAddManualResponse])

    return (
        <>
            <BreadCrumb title={`My Papers - ${data.acronym_title}`} from='Conference' to='My Papers' back={true}/>

            <button className='btn btn-primary btn-lg elevated floating-action floating'
                    onClick={handleShowAddPaperModal}><span class="material-icons">post_add</span> Submit Paper
            </button>
            <Container fluid>
                <div className='row gap-3'>
                    {
                        allPaper.length > 0 ?
                            allPaper.map((item) => {
                                const {
                                    id,
                                    paper_title,
                                    keywords,
                                    abstract,
                                    paper_sequence,
                                    paper_author_data,
                                    track_name,
                                    paper_file,
                                    paper_file_mime_type,
                                    created_by
                                } = item

                                let authorChecker = () => {
                                    return (
                                        paper_author_data.filter((items) => {
                                            return items.email === currentEmail
                                        })
                                    )
                                }

                                return (
                                    authorChecker().length > 0 ?
                                        <Card className='elevated col-6 gap3 track'>
                                            <div className='card-body'>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className='text-primary'><span
                                                            className='badge badge-xl bg-fill-primary text-white'><small>{paper_sequence}</small></span>
                                                        </div>
                                                        <div className='card-title h5 mt-2 mb-3'>{paper_title}</div>
                                                        <div className="grid-container">
                                                            <div className='grid-item'><b>Abstract</b></div>
                                                            <div className='grid-item'>{abstract}</div>
                                                            <div className='grid-item'><b>Authors</b></div>
                                                            <div className='grid-item'>
                                                                {
                                                                    paper_author_data.length > 0 ?
                                                                        paper_author_data.map((item) => {
                                                                            const {first_name, last_name, email} = item
                                                                            return (
                                                                                <div className='authors-box'>
                                                                                    <span>{first_name} {last_name}</span>
                                                                                    <br/><small>{email}</small></div>
                                                                            )
                                                                        })
                                                                        : ''
                                                                }
                                                            </div>
                                                            <div className='grid-item'><b>Track</b></div>
                                                            <div className='grid-item'> {track_name}</div>
                                                            <div className='grid-item'><b>Keywords</b></div>
                                                            <div className='grid-item'>{keywords}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='card-footer'>
                                                {
                                                    paper_file ? <a className='btn btn-light btn-sm  me-3 elevated'
                                                                    href={`data:${paper_file_mime_type};base64,${paper_file}`}
                                                                    download={paper_sequence}><span
                                                        className="material-icons">file_download</span> Download</a> : ''
                                                }
                                                {
                                                    currentID == created_by ?
                                                        <>
                                                            <Link
                                                                className='btn  btn-light btn-sm me-3 elevated'
                                                                title='Edit'
                                                                onClick={() => handleShowEditPaperModal(id)}><span
                                                                className="material-icons">edit_note</span> Edit</Link>
                                                            <Link
                                                                className='btn  btn-light btn-sm me-3 elevated'
                                                                title='Delete'
                                                                onClick={() => handleDeletePaper(id)}><span
                                                                className="material-icons">delete</span> Delete</Link>
                                                            <Link
                                                                className='btn  btn-light btn-sm me-3 elevated'
                                                                title='Manage Author'
                                                                onClick={() => handleShowManageAuthorModal(id)}><span
                                                                className="material-icons">group_add</span> Authors</Link>

                                                        </>
                                                        : ''
                                                }

                                            </div>
                                        </Card>
                                        : ''
                                )
                            }) : ''
                        // <div className='card bg-fill-light-redish'><div className='card-body'><span class='material-icons text-danger'>priority_high</span> You have not submitted any paper yet.</div></div>
                    }
                </div>
            </Container>
            {/* Add Paper */}
            <Modal show={showAddPaperModal} onHide={handleCloseAddPaperModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Paper</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmitAddPaper}>
                        <div className='mb-3'>
                            <Form.Group className='mb-4' controlId="" label="Select Track">
                                <Form.Label>Select Track</Form.Label>
                                <Form.Select name='track_id' value={data.track_id} onChange={handleChangePaperAdd}
                                             required>
                                    <option>Select</option>
                                    {
                                        trackList.map((item) => {
                                            const {id, track_name} = item
                                            return <option value={id} key={id}>{track_name}</option>
                                        })
                                    }
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className='mb-4' controlId="" label="Paper Title">
                                <Form.Label>Paper Title</Form.Label>
                                <Form.Control type="text" placeholder="Paper Title" name='paper_title'
                                              value={dataPaperAdd.paper_title} onChange={handleChangePaperAdd}
                                              required/>
                            </Form.Group>
                            <Form.Group className='mb-4' controlId="" label="Keywords">
                                <Form.Label>Keywords</Form.Label>
                                <Form.Control type="text" placeholder="Keywords" name='keywords'
                                              value={dataPaperAdd.keywords} onChange={handleChangePaperAdd}/>
                            </Form.Group>
                            <Form.Group className='mb-4' controlId="" label="Abstract">
                                <Form.Label>Abstract</Form.Label>
                                <Form.Control as="textarea" style={{height: '100px'}} placeholder="Abstract"
                                              name='abstract' value={dataPaperAdd.abstract}
                                              onChange={handleChangePaperAdd} required/>
                            </Form.Group>
                            <Form.Group className='mb-4' controlId="" label="Upload Paper">
                                <Form.Label>Upload Paper</Form.Label>
                                <Form.Control type="file" placeholder="Upload Paper" name='paper_file'
                                              onChange={handleChangeFile}/>
                            </Form.Group>
                        </div>
                        <div className='text-end'>
                            <button className="btn btn-secondary" onClick={handleCloseAddPaperModal}
                                    type='reset'>Cancel
                            </button>
                            <button className="btn btn-primary primary ms-3" type='submit'>Submit</button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
            {/* Edit Paper */}
            <Modal show={showEditPaperModal} onHide={handleCloseEditPaperModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Paper</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmitEditPaper}>
                        <div className='mb-3'>
                            <Form.Group className='mb-4' controlId="" label="Select Track">
                                <Form.Label>Select Track</Form.Label>
                                <Form.Select name='track_id' value={dataPaperEdit.track_id}
                                             onChange={handleChangePaperEdit} required>
                                    <option>Select</option>
                                    {
                                        trackList.map((item) => {
                                            const {id, track_name} = item
                                            return <option value={id} key={id}>{track_name}</option>
                                        })
                                    }
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className='mb-4' controlId="" label="Paper Title">
                                <Form.Label>Paper Title</Form.Label>
                                <Form.Control type="text" placeholder="Paper Title" name='paper_title'
                                              value={dataPaperEdit.paper_title} onChange={handleChangePaperEdit}
                                              required/>
                            </Form.Group>
                            <Form.Group className='mb-4' controlId="" label="Keywords">
                                <Form.Label>Keywords</Form.Label>
                                <Form.Control type="text" placeholder="Keywords" name='keywords'
                                              value={dataPaperEdit.keywords} onChange={handleChangePaperEdit}/>
                            </Form.Group>
                            <Form.Group className='mb-4' controlId="" label="Abstract">
                                <Form.Label>Abstract</Form.Label>
                                <Form.Control as="textarea" style={{height: '100px'}} placeholder="Abstract"
                                              name='abstract' value={dataPaperEdit.abstract}
                                              onChange={handleChangePaperEdit} required/>
                            </Form.Group>

                        </div>
                        <div className='text-end'>
                            <button className="btn btn-clear secondary ms-3" onClick={handleCloseEditPaperModal}
                                    type='reset'>Cancel
                            </button>
                            <button className="btn btn-clear Priamry" type='submit'>Submit</button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Paper Author */}
            <Modal show={showManageAuthorModal} onHide={handleCloseManageAuthorModal} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Manage Authors</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-6" style={{minHeight: '20rem'}}>
                            <div className='mb-3 search'>
                                <p>Search here for users to add as author.</p>
                                <FloatingLabel className='mb-3' controlId="" label="Search Author">
                                    <Form.Control autoComplete='off' type="text" placeholder="Search Author"
                                                  name='author_person' value={searchText} onChange={loadAuthor}/>
                                </FloatingLabel>

                                <div className='suggestionList elevated'>
                                    {
                                        userList.map((item) => {
                                            const {id, first_name, last_name, email} = item
                                            return (
                                                <div className='d-flex justify-content-between py-1 px-3 listItem'>
                                                    <div>
                                                        <h5 className='mb-1'>{first_name} {last_name}</h5>
                                                        <span><small>{email}</small></span>
                                                    </div>
                                                    <div>

                                                        <button className='btn btn-sm btn-classic btn-primary'
                                                                onClick={() => addAuthor(paperId, id)}><span
                                                            className="material-icons">add</span> Add
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            </div>

                            <div className='text-center bg-fill-light py-2 mb-2'>
                                <h3>OR</h3>
                                <small>If not found you can add an author manually here.</small>
                            </div>
                            <div className='cozy'>
                                <Form onSubmit={handleSubmitAuthorAddManual}>
                                    <div className="row">
                                        <div className='col-lg-6 mb-1'>
                                            <label>First Name</label>
                                            <Form.Control type="text" placeholder="" name='first_name'
                                                          value={authorAddManual.first_name}
                                                          onChange={handleChangeAuthorAddManual} required/>
                                        </div>
                                        <div className='col-lg-6 mb-1'>
                                            <label>Last Name</label>
                                            <Form.Control type="text" placeholder="" name='last_name'
                                                          value={authorAddManual.last_name}
                                                          onChange={handleChangeAuthorAddManual} required/>
                                        </div>
                                        <div className='col-lg-12 mb-1'>
                                            <label>Email</label>
                                            <Form.Control type="text" placeholder="" name='email'
                                                          value={authorAddManual.email}
                                                          onChange={handleChangeAuthorAddManual} required/>
                                        </div>
                                        <div className='col-lg-6 mb-1'>
                                            <label>Country</label>
                                            <Form.Select name='country' value={authorAddManual.country}
                                                         onChange={handleChangeAuthorAddManual} required>
                                                <option>Select</option>
                                                {
                                                    CountryList.map((item, key) => {
                                                        return <option value={item} key={key}>{item}</option>
                                                    })
                                                }
                                            </Form.Select>
                                        </div>
                                        <div className='col-lg-6 mb-1'>
                                            <label>Affiliation</label>
                                            <Form.Control type="text" placeholder="" name='affiliation'
                                                          value={authorAddManual.affiliation}
                                                          onChange={handleChangeAuthorAddManual} required/>
                                        </div>
                                    </div>
                                    <div className='text-end'>
                                        <button className="btn btn-primary btn-classic btn-xs" type='submit'><span
                                            className="material-icons">group_add</span> Add Author
                                        </button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <div className="col-6 border-start">
                            {
                                <>
                                    <h3><strong>Added Authors</strong></h3>
                                    <ul className='list-unstyled'>
                                        {
                                            singlePaperAuthor.map((item) => {
                                                const {paper_id, id, first_name, last_name, email} = item
                                                return (
                                                    <div
                                                        className='d-flex justify-content-between align-items-center mb-1 listItem'>
                                                        <div>
                                                            <h5 className='m-0'>{first_name} {last_name} / <span><small>{email}</small></span>
                                                            </h5>
                                                        </div>
                                                        <div>
                                                            <button className='btn btn-xs btn-classic btn-danger'
                                                                    onClick={() => deleteAuthor(paper_id, id)}><span
                                                                className="material-icons">delete_forever</span> Remove
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </ul>
                                </>
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default MyPapers