import {Link, NavLink, useParams} from 'react-router-dom'
import logo from '../../static/images/logo-180-50.png'

import {Container, Nav, Navbar} from "react-bootstrap"

import {useEffect, useState} from 'react'
import Axios from 'axios'
import AlertCustom from '../../components/elements/AlertCustom'
import organizingCommitteeType from '../../data/OrganizingCommitteeType'

const baseURL = process.env.REACT_APP_ENDPOINT

const CfpView = () => {
    //Define
    const detailsId = useParams()

    const [alertData, setAlertData] = useState({
        'alertShow': false,
        'alertVariant': null,
        'alertMessage': null
    })

    const [octData, setOctData] = useState([])
    const [rightData, setRightData] = useState([])

    //Action
    useEffect(() => {
        if (alertData.alertShow) {
            setTimeout(() => {
                setAlertData({
                    'alertShow': false,
                    'alertVariant': '',
                    'alertMessage': null
                })
            }, 7000)
        }
    }, [alertData])

    useEffect(() => {
        Axios.get(`${baseURL}/site/cfp/${detailsId.id}`)
            .then(res => {
                setRightData(res.data.result)

                const octLookup = organizingCommitteeType.reduce((acc, {value, label}) => {
                    acc[value] = label;
                    return acc;
                }, {});

                let cfp_data = res.data.result.cfp_data
                //Merge with organizing_Committee_Type
                const merged_label = cfp_data.map(item => ({
                    id: item.id,
                    organizing_committee_type: item.organizing_committee_type,
                    organizing_committee_type_label: octLookup[item.organizing_committee_type],
                    first_name: item.first_name,
                    last_name: item.last_name,
                    sequence: item.sequence,
                    affiliation: item.affiliation,
                    country: item.country
                }));

                //Group by label
                const grouped_label = Object.groupBy(merged_label, ({organizing_committee_type_label}) => organizing_committee_type_label)

                Object.keys(grouped_label).forEach(key => {
                    grouped_label[key].sort((a, b) => a.sequence - b.sequence);
                });

                const result = Object.entries(grouped_label).map(([key, value]) => ({
                    oct: key,
                    list: value.sort((a, b) => a.sequence - b.sequence)
                }));

                setOctData(result)
            })
            .catch(
                error => {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'danger',
                        'alertMessage': error.response.data
                    })
                }
            )

    }, [])

    function createMarkup() {
        return {__html: rightData.cfp_details};
    }

    console.log(rightData)

    return (
        <>
            <div className='sticky-top'>
                <Navbar expand="lg" className="navbar-inverse  bg-body-tertiary">
                    <Container>
                        <Link to={"https://easymeet.ws/"} title='EasyMeet'><img src={logo} alt='Logo'/></Link>
                        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                        <Navbar.Collapse>
                            <Nav className="ms-auto">
                                <NavLink to={'/'} className='btn'>Login</NavLink>
                                {
                                    rightData.cfp_file ? <a className='btn btn-primary'
                                                            href={`data:${rightData.cfp_file_mime_type};base64,${rightData.cfp_file}`}
                                                            download={detailsId.id}><span
                                        className="material-icons">cloud_download</span> Download</a> : ''
                                }
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
            <AlertCustom data={alertData}/>

            <main className='classicStyle container'>
                {
                    rightData.banner ? <img src={`data:${rightData.banner_mime_type};base64,${rightData.banner}`}
                                            className='banner curved w-100 my-2' alt='Banner'/>
                        : <>
                            <div className='card bg-fill-light-gray my-3'>
                                <div className='card-body'><h3 className='text-center'
                                                               style={{'fontSize': '4rem'}}>{rightData.acronym_title}</h3>
                                    <h1 className='text-center my-4'
                                        style={{'fontSize': '2.5rem', 'fontWeight': '700'}}>{rightData.event_name}</h1>
                                </div>
                            </div>
                        </>
                }


                <div className="card bg-fill-light elevated p-4 my-3">
                    <div className="card-body">
                        <div className='row'>
                            <div className="col-4 border-end">
                                <h2>Organizing Committee</h2>
                                <br/>
                                {
                                    octData.map((item) => {
                                        let {oct, list} = item
                                        return (
                                            <>
                                                <h4><strong>{oct}</strong></h4>
                                                <ul className='list-unstyled'>
                                                    {
                                                        list.map((item) => {
                                                            let {first_name, last_name, affiliation, country} = item
                                                            return (
                                                                <>
                                                                    <div
                                                                        className='d-flex justify-content-between mb-1'>
                                                                        <div className='col'>
                                                                            {first_name} {last_name}, <small>{affiliation}, {country}</small>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </>
                                        )
                                    })
                                }
                            </div>
                            <div className="col-8">
                                <h2>Call For Papers</h2>
                                <br/>
                                <div dangerouslySetInnerHTML={createMarkup()}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <footer className="bg-fill-light-redish py-3 text-center">
                Copyright © 2023 Easy Meet. All rights reserved
            </footer>
        </>
    )
}

export default CfpView