import { NavLink } from 'react-router-dom'
import { menuItems } from './Menu'
import { userMenuItems } from './MenuUser'
import SubNav from './SubNav'
import SubNavLabel from './SubNavLabel'

const Sidebar = () => {
    let currentRole = localStorage.getItem('role')

    return (
        <ul className='sidebar-nav'>

            {
                currentRole === 'ADMIN' ? 
                menuItems.map((item) => {
                    const { title, url, icon, submenu } = item

                    return (
                        <li className='subNav' key={title}>
                            {
                                submenu ? (
                                    <>
                                        <SubNavLabel title={title} url={url} icon={icon} />
                                        {/* <SubNav data={submenu} /> */}
                                        {
                                            title === 'Profile' ? <SubNav data={submenu} hasLogout={true} /> : <SubNav data={submenu} />
                                        }
                                    </>
                                ) : <NavLink to={url} title={title}><span className="material-icons">{icon}</span><span className='title'>{title}</span></NavLink>
                            }
                        </li>
                    )
                })
                :
                userMenuItems.map((item) => {
                    const { title, url, icon, submenu } = item

                    return (
                        <li className='subNav' key={title}>
                            {
                                submenu ? (
                                    <>
                                        <SubNavLabel title={title} url={url} icon={icon} />
                                        {/* <SubNav data={submenu} /> */}
                                        {
                                            title === 'Profile' ? <SubNav data={submenu} hasLogout={true} /> : <SubNav data={submenu} />
                                        }
                                    </>
                                ) : <NavLink to={url} title={title}><span className="material-icons">{icon}</span><span className='title'>{title}</span></NavLink>
                            }
                        </li>
                    )
                })
            }
        </ul >
    )
}

export default Sidebar