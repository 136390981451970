import BreadCrumb from '../../components/elements/BreadCrumb';
import {Card, CardBody, Dropdown} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import Axios from 'axios';
import {Link, useNavigate} from 'react-router-dom'
import {useUserContext} from "../../contexts/UserContext";
import Loader from "../../components/elements/loader/Loader";
import useAjax from "../../useAjax";
import {useApplicationContext} from "../../contexts/ApplicationContext";

const baseURL = process.env.REACT_APP_ENDPOINT

const AllConference = () => {
    const [data, setData] = useState([])
    let tokenStr = localStorage.getItem('access_token')
    let currentRole = localStorage.getItem('role');
    let currentID = parseInt(localStorage.getItem('id'))

    const [, dispatchApplicationContext] = useApplicationContext();

    const [userContext] = useUserContext();

    const [getConferenceCall, conferenceData] = useAjax('get', `${baseURL}/conference`);
    const [approveConfCall, approveConfCallSuccess] = useAjax('post');
    const [rejectConfCall, rejectConfCallSuccess] = useAjax('post');
    const [blockConfCall, blockConfCallSuccess] = useAjax('post');
    const [unBlockConfCall, unBlockConfCallSuccess] = useAjax('post');

    const navigate = useNavigate()

    useEffect(() => {
        if (conferenceData) {
            setData(conferenceData?.result);
        }
    }, [conferenceData])

    useEffect(() => {
        getConferenceCall();
    }, [])

    const alertSuccessMsg = (msg = '') => {
        dispatchApplicationContext({type: 'serviceSuccess', payload: {statusMessage: msg}})
        getConferenceCall();
    }

    useEffect(() => {
        alertSuccessMsg(approveConfCallSuccess?.message);
    }, [approveConfCallSuccess]);

    useEffect(() => {
        alertSuccessMsg(rejectConfCallSuccess?.message);
    }, [rejectConfCallSuccess]);

    useEffect(() => {
        alertSuccessMsg(blockConfCallSuccess?.message);
    }, [blockConfCallSuccess]);

    useEffect(() => {
        alertSuccessMsg(unBlockConfCallSuccess?.message);
    }, [unBlockConfCallSuccess]);

    const changeStatus = (id, stat) => {
        if (stat === 1) {
            const confirmation = window.confirm("Do you want to Approve this Conference?")
            if (confirmation) {
                approveConfCall({URL: `${baseURL}/conference/approve/${id}`});
            }
        } else if (stat === 2) {
            const confirmation = window.confirm("Do you want to Reject this Conference?")
            if (confirmation) {
                rejectConfCall({URL: `${baseURL}/conference/reject/${id}`});
            }
        } else if (stat === 3) {
            const confirmation = window.confirm("Do you want to Block this Conference?")
            if (confirmation) {
                blockConfCall({URL: `${baseURL}/conference/block/${id}`});
            }
        } else if (stat === 4) {
            const confirmation = window.confirm("Do you want to UnbBlock this Conference?")
            if (confirmation) {
                unBlockConfCall({URL: `${baseURL}/conference/unblock/${id}`});
            }
        }
    }
    return (
        <>

            <BreadCrumb title='All Conference' from='Conference' to='All Conference' back={true}/>

            {
                data.length > 0 ?
                    data.map((item) => {
                        const {
                            id,
                            event_name,
                            acronym_title,
                            venue,
                            city,
                            state,
                            country,
                            logo,
                            logo_mime_type,
                            start_date,
                            end_date,
                            conference_status,
                            conf_id,
                            permissions,
                            oc_permissions
                        } = item

                        let statusOptions = null
                        let statusLabel = null
                        // let statusClass = null
                        if (conference_status == 0) {
                            statusOptions = (<><Dropdown.Item
                                onClick={() => changeStatus(id, 1)}>Approve</Dropdown.Item><Dropdown.Item
                                onClick={() => changeStatus(id, 2)}>Reject</Dropdown.Item><Dropdown.Item
                                onClick={() => changeStatus(id, 3)}>Block</Dropdown.Item></>)
                            statusLabel = "Pending"
                            // statusClass = "secondary"
                        } else if (conference_status == 1) {
                            statusOptions = (<><Dropdown.Item
                                onClick={() => changeStatus(id, 3)}>Block</Dropdown.Item></>)
                            statusLabel = "Approved"
                            // statusClass = "primary"
                        } else if (conference_status === 2) {
                            statusOptions = (<></>)
                            statusLabel = "Rejected"
                            // statusClass = "danger"
                        } else if (conference_status == 3) {
                            statusOptions = (<><Dropdown.Item
                                onClick={() => changeStatus(id, 4)}>Unblock</Dropdown.Item></>)
                            statusLabel = "Blocked"
                            // statusClass = "warning"
                        }

                        let gcpcChecker = oc_permissions.includes(currentID)

                        return (
                            <Card className='mb-3 elevated' key={conf_id}>
                                <CardBody>
                                    <div className="row">
                                        <div className="" style={{width: '100px'}}>
                                            {
                                                logo ? <img src={`data:${logo_mime_type};base64,${logo}`}
                                                            className='card-logo thumbnail' alt='Logo'/> : <div
                                                    className='defaultLogo thumbnail elevated'>{acronym_title}</div>
                                            }
                                        </div>
                                        <div className="col">
                                            <Card.Title>{event_name}</Card.Title>
                                            <h3>{acronym_title}</h3>
                                            <address>
                                                <span className='d-block'>{venue}</span>
                                                <span className='d-block'>{city}, {state}</span>
                                                <span className='d-block'>{country}</span>
                                            </address>
                                            <span
                                                className="material-icons size-16">today</span> {start_date} To {end_date}
                                            <Link className='btn btn-clear btn-sm ml-2 me-2 ms-2'
                                                  to={`/conference/${id}`} title='Details'><span
                                                className="material-icons">open_in_new</span> Details</Link>
                                            <Link className='btn btn-clear btn-sm me-2 '
                                                  to={`/cfp/${conf_id}`} title='Visit CFP' target='_blank'><span
                                                className="material-icons">open_in_new</span> View CFP</Link>
                                        </div>
                                        <div className="border-start" style={{width: '320px'}}>


                                            {
                                                conference_status === 1 ? <>
                                                    {
                                                        permissions.includes("SUPERCHAIR") || currentRole === 'ADMIN' ? <>
                                                            <Link
                                                            className='btn btn-classic btn-light btn-sm mb-2 me-2 fw-8 elevated'
                                                            to={`/conference/cfp/${id}`} title='Manage CFP'><span
                                                            className="material-icons">post_add</span> Manage
                                                            CFP</Link><Link
                                                                className='btn btn-classic btn-light btn-sm mb-2 me-2 fw-8 elevated'
                                                                to={`/conference/editConference/${id}`}
                                                                title='Edit Conference'><span
                                                                className="material-icons">edit_note</span> Configuration</Link></> : ''
                                                    }
                                                    {
                                                        permissions.includes("SUPERCHAIR") || currentRole === 'ADMIN' || gcpcChecker === true ? <>
                                                            <Link
                                                            className='btn btn-classic btn-light btn-sm mb-2 me-2 fw-8 elevated'
                                                            to={`/conference/tracks/${id}`} title='Manage Tracks'><span
                                                            className="material-icons">edit_road</span> Config
                                                            Tracks</Link></> : ''
                                                    }
                                                    {
                                                        permissions.includes("SUPERCHAIR") || permissions.includes("TRACKCHAIR") || currentRole === 'ADMIN' || gcpcChecker === true ? <>
                                                            <Link
                                                                className='btn btn-classic btn-light btn-sm mb-2 me-2 fw-8 elevated'
                                                                to={`/conference/tracks/list/${id}`}
                                                                title='Tracks'><span
                                                                className="material-icons">edit_road</span> TPC
                                                                Members</Link></> : ''
                                                    }


                                                    <Link
                                                        className='btn btn-classic btn-light btn-sm mb-2 me-2 fw-8 elevated'
                                                        to={`/conference/mypapers/${id}`} title='My Papers'><span
                                                        className="material-icons">article</span> My
                                                        Papers</Link>
                                                    {
                                                        currentRole === 'ADMIN' ?
                                                            <Link
                                                                className='btn btn-classic btn-light btn-sm mb-2 me-2 fw-8 elevated'
                                                                to={`/conference/papers/${id}`} title='All Papers'><span
                                                                className="material-icons">article</span> All
                                                                Papers</Link>
                                                            : ''
                                                    }

                                                    <Link
                                                        className='btn btn-classic btn-light btn-sm mb-2 me-2 fw-8 elevated'
                                                        to={`/conference/reviewPapers/${id}`} title='Review Paper'><span
                                                        className="material-icons">open_in_new</span> Review
                                                        Paper</Link>

                                                </> : ''
                                            }
                                            {
                                                currentRole === 'ADMIN' ?
                                                    <Dropdown className='d-inline-block align-top'>
                                                        <Dropdown.Toggle variant='light'
                                                                         className='btn-classic btn-sm fw-8 elevated'>{statusLabel}</Dropdown.Toggle>
                                                        <Dropdown.Menu>{statusOptions}</Dropdown.Menu>
                                                    </Dropdown>
                                                    : <button
                                                        className='btn btn-classic btn-light btn-sm mb-2 me-2 fw-8 elevated'>{statusLabel}</button>

                                            }

                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        )
                    })
                    : (<Loader/>)
                // <div className='card bg-fill-light-redish'><div className='card-body'><span className='material-icons text-danger'>priority_high</span> No Conference is submitted yet.</div></div>
            }

        </>
    )
}

export default AllConference