import Axios from 'axios'
import loginLogo from '../../static/images/logo-180-50.png'
import React, { useState } from 'react'
import { Card, CardBody, FloatingLabel, Form, FormGroup, InputGroup } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import AlertCustom from '../../components/elements/AlertCustom'
import styles from './login.module.css';

const baseURL = process.env.REACT_APP_ENDPOINT

const ForgotPassword = () => {
    const navigate = useNavigate()
    const [alertData, setAlertData] = useState({
        'alertShow': false,
        'alertVariant': null,
        'alertMessage': null
    })

    const [data, setData] = useState({
        email: '',
        op_type: 'FORGOT_PASSWORD'
    })

    const handleChange = (e) => {
        setData((prev) => {
            const name = e.target.name
            const value = e.target.value
            return {
                ...prev, [name]: value
            }
        })
    }

    const formSubmit = (e) => {
        e.preventDefault()

        setData((prev) => {
            const name = e.target.name
            const value = e.target.value
            return {
                ...prev, [name]: value
            }
        })

        Axios.post(`${baseURL}/get_link`, data)
            .then((res) => {
                if (res.data.success === false) {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'warning',
                        'alertMessage': res.data.message
                    })
                } else {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'success',
                        'alertMessage': res.data.message
                    })

                    setTimeout(() => {
                        navigate('/')
                    }, 3000)
                }
            })
            .catch(
                error => {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'danger',
                        'alertMessage': error.response.data.message
                    })
                }
            )

    }
    return (
        <>
            <AlertCustom data={alertData} /><div className={styles.loginPage}>
                <div className={styles.leftPanel}>
                    <div>
                        <svg width="301" height="77" viewBox="0 0 301 77" xmlns="http://www.w3.org/2000/svg">
                            <text id="EASYMEET" x="3" y="74" font-family="Impact" font-size="74" font-weight="800" fill="#ffffff">EASYMEET</text>
                        </svg>
                        <div className={styles.leftPanelMessage}>The easiest platform for creating conferences.</div>
                    </div>

                    <img className={styles.leftPanelOverlayImg} src="/images/1206.jpg" alt={''} />


                </div>
                <div className={styles.rightPanel}>
                    <div className='d-flex justify-content-center align-items-center login'>
                        <Card className='login-card '>
                            <CardBody>
                                <Form onSubmit={formSubmit} >
                                    <h1>Enter Email to reset password</h1>

                                    {/* <Link to={'/'}><img src={loginLogo} className='d-block mx-auto' alt='Eeasy Meet' /></Link> */}
                                    <FormGroup className="mb-3 mt-4">
                                        <FloatingLabel controlId="floatingInput" label="Email">
                                            <Form.Control type="email" name='email' placeholder="Email" value={data.email} onChange={handleChange} required />
                                            <span></span>
                                        </FloatingLabel>
                                    </FormGroup>
                                    <input type="submit" value="Reset" className='btn btn-primary w-100' />
                                    <p className='my-3 text-center'><Link to={'/'}><span className="material-icons">chevron_left</span> Back to Login</Link></p>
                                </Form>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword