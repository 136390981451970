import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Card, CardBody, Form, Container, CardFooter } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import BreadCrumb from '../../components/elements/BreadCrumb'

import Modal from 'react-bootstrap/Modal';
import AlertCustom from '../../components/elements/AlertCustom'

const baseURL = process.env.REACT_APP_ENDPOINT

const Tracks = () => {

    //Define 
    let tokenStr = localStorage.getItem('access_token')
    const navigate = useNavigate()
    const detailsId = useParams()
    const [alertData, setAlertData] = useState({
        'alertShow': false,
        'alertVariant': null,
        'alertMessage': null
    })
    const [data, setData] = useState({
        "event_name": "",
        "acronym_title": "",
    })
    const [allTracks, setAllTracks] = useState([])
    const [userTempList, setUserTempList] = useState([])
    const [userList, setUserList] = useState([])
    const [chairList, setChairList] = useState([])

    const [showTrackModal, setShowTrackModal] = useState(false);
    const [showTrackEditModal, setShowTrackEditModal] = useState(false);
    const [showChairModal, setShowChairModal] = useState(false);
    const [searchText, setSearchText] = useState('')
    const [manageChairTrackId, setManageChairTrackId] = useState(null)


    const [dataTrackAdd, setDataTrackAdd] = useState({
        'conf_id': detailsId.id,
        'track_name': '',
        'track_short_name': '',
        'track_description': ''
    })
    const [dataTrackEdit, setDataTrackEdit] = useState({
        'track_name': '',
        'track_short_name': '',
        'track_description': ''
    })
    const [singleTrackChair, setSingleTrackChair] = useState([])


    //Action
    const getSingleTrackChair = (id) => {
        Axios.get(`${baseURL}/track/${id}`, { headers: { "Authorization": `Bearer ${tokenStr}` } })
            .then(res => {
                setSingleTrackChair(res.data.result.track_chair_data)
            })
            .catch(
                error => {
                    if (error.response.status === 401) {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'danger',
                            'alertMessage': 'You are not authorized to access this content.You will be logged out. Please login again!'
                        })
                        setTimeout(() => {
                            navigate('/')
                        }, 6000)
                    } else {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'warning',
                            'alertMessage': error.response.data
                        })
                    }
                }
            )
    }
    const handleCloseTrackModal = () => setShowTrackModal(false);
    const handleShowTrackModal = () => setShowTrackModal(true);

    const handleCloseTrackEditModal = () => setShowTrackEditModal(false);
    const handleShowTrackEditModal = (id) => {
        //filter single track from all track details
        const singleTrack = allTracks.filter((item) => { return item.id === id })
        setDataTrackEdit({
            'track_id': singleTrack[0].id,
            'track_name': singleTrack[0].track_name,
            'track_short_name': singleTrack[0].track_short_name,
            'track_description': singleTrack[0].track_description
        })

        setShowTrackEditModal(true)
    };

    const handleCloseChairModal = () => setShowChairModal(false);
    const handleShowChairModal = (id) => {
        //filter single track from all track details
        // const singleTrack = allTracks.filter((item) => { return item.id === id })
        // setChairList(singleTrack[0].track_chair_data)

        getSingleTrackChair(id)


        setManageChairTrackId(id)
        setShowChairModal(true)
    };

    useEffect(() => {
        if (alertData.alertShow) {
            setTimeout(() => {
                setAlertData({
                    'alertShow': false,
                    'alertVariant': '',
                    'alertMessage': null
                })
            }, 7000)
        }
    }, [alertData])

    useEffect(() => {

        if (detailsId.id > 0) {

            Axios.get(`${baseURL}/site/conf/short/${detailsId.id}`)
                .then(res => {
                    setData(res.data.result[0])
                })
                .catch(
                    error => {
                        if (error.response.status === 401) {
                            setAlertData({
                                'alertShow': true,
                                'alertVariant': 'danger',
                                'alertMessage': 'You are not authorized to access this content.You will be logged out. Please login again!'
                            })
                            setTimeout(() => {
                                navigate('/')
                            }, 6000)
                        } else {
                            setAlertData({
                                'alertShow': true,
                                'alertVariant': 'warning',
                                'alertMessage': error.response.data
                            })
                        }
                    }
                )

            Axios.get(`${baseURL}/track/all/${detailsId.id}`, { headers: { "Authorization": `Bearer ${tokenStr}` } })
                .then(res => {
                    setAllTracks(res.data.result)
                })
                .catch(
                    error => {
                        if (error.response.status === 401) {
                            setAlertData({
                                'alertShow': true,
                                'alertVariant': 'danger',
                                'alertMessage': 'You are not authorized to access this content.You will be logged out. Please login again!'
                            })
                            setTimeout(() => {
                                navigate('/')
                            }, 6000)
                        } else {
                            setAlertData({
                                'alertShow': true,
                                'alertVariant': 'warning',
                                'alertMessage': error.response.data
                            })
                        }
                    }
                )

            Axios.get(`${baseURL}/users`, { headers: { "Authorization": `Bearer ${tokenStr}` } })
                .then(res => {
                    setUserTempList(res.data.result.data)
                })
                .catch(
                    error => {
                        if (error.response.status === 401) {
                            setAlertData({
                                'alertShow': true,
                                'alertVariant': 'danger',
                                'alertMessage': 'You are not authorized to access this content.You will be logged out. Please login again!'
                            })
                            setTimeout(() => {
                                navigate('/')
                            }, 6000)
                        } else {
                            setAlertData({
                                'alertShow': true,
                                'alertVariant': 'warning',
                                'alertMessage': error.response.data
                            })
                        }
                    }
                )
        }
    }, [detailsId, chairList])

    const handleChangeTrackAdd = (e) => {
        let name = e.target.name
        let value = e.target.value

        setDataTrackAdd((prev) => {
            return {
                ...prev, [name]: value
            }
        })
    }
    const handleChangeTrackEdit = (e) => {
        let name = e.target.name
        let value = e.target.value

        setDataTrackEdit((prev) => {
            return {
                ...prev, [name]: value
            }
        })
    }

    const handleSubmitTrackAdd = (e) => {
        e.preventDefault()
        Axios.post(`${baseURL}/track/`, dataTrackAdd, { headers: { "Authorization": `Bearer ${tokenStr}` } })
            .then((res) => {
                setAlertData({
                    'alertShow': true,
                    'alertVariant': 'success',
                    'alertMessage': res.data.message
                })
                window.location.reload()
            })
            .catch(
                error => {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'danger',
                        'alertMessage': error.response.data
                    })
                }
            )
    }

    const handleSubmitTrackEdit = (e) => {
        e.preventDefault()

        const confirmation = window.confirm("Are you sure to Update this Track?")
        if (confirmation) {
            Axios.post(`${baseURL}/track/update/${detailsId.id}`, dataTrackEdit, { headers: { "Authorization": `Bearer ${tokenStr}` } })
                .then((res) => {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'success',
                        'alertMessage': res.data.message
                    })
                    window.location.reload()
                })
                .catch(
                    error => {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'danger',
                            'alertMessage': error.response.data
                        })
                    }
                )
        }
    }

    const handleDeleteTrack = (id) => {

        const confirmation = window.confirm("Are you sure to Delete this Track?")
        if (confirmation) {
            Axios.delete(`${baseURL}/track/${id}`, { headers: { "Authorization": `Bearer ${tokenStr}` } })
                .then((res) => {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'success',
                        'alertMessage': res.data.message
                    })
                    window.location.reload()
                })
                .catch(
                    error => {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'danger',
                            'alertMessage': error.response.data
                        })
                    }
                )
        }
    }


    const loadChair = (e) => {
        setSearchText(e.target.value)

        if (searchText.length > 1) {
            setUserList(userTempList.filter((item) => {
                item.email = item.email.toLocaleLowerCase()
                item.first_name = item.first_name.toLocaleLowerCase()
                item.last_name = item.last_name.toLocaleLowerCase()
                const compareString = searchText.toLocaleLowerCase()
                return item.email.startsWith(compareString) || item.first_name.startsWith(compareString) || item.last_name.startsWith(compareString)
            }))
        } else {
            setUserList([])
        }
    }

    const addChair = (track_id, chair_id) => {

        Axios.post(`${baseURL}/track/chair-assign/${track_id}`, { 'chair_id': chair_id }, { headers: { "Authorization": `Bearer ${tokenStr}` } })
            .then((res) => {
                setAlertData({
                    'alertShow': true,
                    'alertVariant': 'success',
                    'alertMessage': res.data.message
                })
                getSingleTrackChair(track_id)

            })
            .catch(
                error => {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'danger',
                        'alertMessage': error.response.data
                    })
                }
            )

    }

    const deleteChair = (track_id, chair_id) => {

        const confirmation = window.confirm("Are you sure to delete this Track Chair?")
        if (confirmation) {
            Axios.delete(`${baseURL}/track/chair/${chair_id}`, { headers: { "Authorization": `Bearer ${tokenStr}` } })
                .then((res) => {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'success',
                        'alertMessage': res.data.message
                    })
                    getSingleTrackChair(track_id)

                })
                .catch(
                    error => {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'danger',
                            'alertMessage': error.response.data
                        })
                    }
                )
        }
    }

    return (
        <>
            <AlertCustom data={alertData} />
            <BreadCrumb title={`${data.acronym_title} Tracks`} from='Conference' to='Tracks' back={true} />
            {/* <div className='col-xs-12 col-md-6 mb-3'>
                    <Card className='bg-fill-light-redish cursor-pointer' onClick={handleShowTrackModal}>
                        <CardBody className='p-4 text-center track d-flex justify-content-center align-items-center'>
                            <h1><span className="material-icons">add_box</span> Add Track</h1>
                        </CardBody>
                    </Card>
                </div> */}

            <button className='btn btn-primary btn-lg elevated floating-action floating' onClick={handleShowTrackModal}><span className="material-icons">addchart</span> Add Track</button>
            <Container fluid>
                <div className='row gap-3'>
                    {
                        allTracks.map((item) => {
                            const { id, track_name, track_short_name, track_description, track_chair_data } = item
                            return (
                                <Card className='elevated col-6 gap3 track'>
                                    <CardBody>
                                        <h4>{track_name}</h4>
                                        <div className='card-title h5 mt-2 mb-3'>{track_short_name}</div>
                                        <div className="grid-container">
                                            <div className='grid-item'><b>Description</b></div>
                                            <div className='grid-item'>{track_description}</div>
                                            <div className='grid-item'><b>Track Chairs</b></div>
                                            <div className='grid-item'>
                                                <ul className='maxh-7 mb-0 list-unstyled'>
                                                    {
                                                        track_chair_data.map((item) => {
                                                            const { first_name, last_name, email } = item
                                                            return (
                                                                <li>
                                                                    {first_name} {last_name} / <small>{email}</small>
                                                                </li>
                                                            )
                                                        })
                                                    }

                                                </ul>
                                            </div>
                                        </div>
                                    </CardBody>
                                    <CardFooter>
                                        <button className='btn btn-light elevated btn-sm m-2' onClick={() => handleShowTrackEditModal(id)} title='Edit'><span className="material-icons">edit_note</span> Edit Track</button>
                                        <button className='btn btn-light elevated btn-sm m-2' onClick={() => handleDeleteTrack(id)} title='Delete'><span className="material-icons">delete</span> Delete</button>
                                        <button className='btn btn-light elevated btn-sm m-2' onClick={() => handleShowChairModal(id)} title='Manage Chairs'><span className="material-icons ">manage_accounts</span> Manage Chairs</button>
                                    </CardFooter>
                                </Card>
                            )
                        })
                    }
                </div>
            </Container>

            {/* Add Tracks */}
            <Modal show={showTrackModal} onHide={handleCloseTrackModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Track</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmitTrackAdd}>
                        <div className='mb-3'>
                            <Form.Group className='mb-4' controlId="" label="Track Name" >
                                <Form.Label>Track Name</Form.Label>
                                <Form.Control type="text" placeholder="Track Name" name='track_name' value={dataTrackAdd.track_name} onChange={handleChangeTrackAdd} required />
                            </Form.Group>
                            <Form.Group className='mb-4' controlId="" label="Track Short Name" >
                                <Form.Label>Track Short Name</Form.Label>
                                <Form.Control type="text" placeholder="Track Name" name='track_short_name' value={dataTrackAdd.track_short_name} onChange={handleChangeTrackAdd} />
                            </Form.Group>
                            <Form.Group className='mb-4' controlId="" label="Track Description" >
                                <Form.Label>Track Description</Form.Label>
                                <Form.Control as="textarea" style={{ height: '100px' }} placeholder="Track Description" name='track_description' value={dataTrackAdd.track_description} onChange={handleChangeTrackAdd} />
                            </Form.Group>
                        </div>
                        <div className='text-end'>
                            <button className="btn btn-clear secondary ms-3" onClick={handleCloseTrackModal} type='reset'>Cancel</button>
                            <button className="btn btn-clear priamry" type='submit'>Submit</button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Edit Tracks */}
            <Modal show={showTrackEditModal} onHide={handleCloseTrackEditModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Track</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmitTrackEdit}>
                        <div className='mb-3'>
                            <Form.Group className='mb-4' controlId="" label="Track Name" >
                                <Form.Label>Track Name</Form.Label>
                                <Form.Control type="text" placeholder="Track Name" name='track_name' value={dataTrackEdit.track_name} onChange={handleChangeTrackEdit} required />
                            </Form.Group>
                            <Form.Group className='mb-4' controlId="" label="Track Short Name" >
                                <Form.Label>Track Short Name</Form.Label>
                                <Form.Control type="text" placeholder="Track Short Name" name='track_short_name' value={dataTrackEdit.track_short_name} onChange={handleChangeTrackEdit} />
                            </Form.Group>
                            <Form.Group className='mb-4' controlId="" label="Track Description" >
                                <Form.Label>Track Description</Form.Label>
                                <Form.Control as="textarea" style={{ height: '100px' }} placeholder="Track Description" name='track_description' value={dataTrackEdit.track_description} onChange={handleChangeTrackEdit} />
                            </Form.Group>
                        </div>
                        <div className='text-end'>
                            <button className="btn btn-clear secondary ms-3" onClick={handleCloseTrackEditModal} type='reset'>Cancel</button>
                            <button className="btn btn-clear priamry" type='submit'>Submit</button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Chairs */}
            <Modal show={showChairModal} onHide={handleCloseChairModal} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Manage Chairs</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-6" style={{ minHeight: '20rem' }}>
                            <div className='mb-3 search'>
                                <p>Search here for users to add as chairs.</p>
                                <Form.Group className='mb-4' controlId="" label="Search Chair" >
                                    <Form.Label>Search Chair</Form.Label>
                                    <Form.Control autoComplete='off' type="text" placeholder="Search Chair" name='chair_person' value={searchText} onChange={loadChair} />
                                </Form.Group>

                                <div className='suggestionList elevated'>
                                    {
                                        userList.map((item) => {
                                            const { id, first_name, last_name, email } = item
                                            return (
                                                <div className='d-flex justify-content-between py-1 px-3 listItem'>
                                                    <div>
                                                        <h5 className='mb-1'>{first_name} {last_name}</h5>
                                                        <span><small>{email}</small></span>
                                                    </div>
                                                    <div>

                                                        <button className='btn btn-sm btn-classic btn-primary' onClick={() => addChair(manageChairTrackId, id)}><span className="material-icons">add</span> Add</button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                        <div className="col-6 border-start">
                            {
                                <>
                                    <h3><strong>Added Chairs</strong></h3>
                                    <ul className='list-unstyled'>
                                        {
                                            singleTrackChair.map((item) => {
                                                const { track_id, id, first_name, last_name, email } = item
                                                return (
                                                    <div className='d-flex justify-content-between align-items-center mb-1 listItem'>
                                                        <div>
                                                            <h5 className='m-0'>{first_name} {last_name} / <span><small>{email}</small></span></h5>
                                                        </div>
                                                        <div>
                                                            <button className='btn btn-xs btn-classic btn-danger' onClick={() => deleteChair(track_id, id)}><span className="material-icons">delete_forever</span> Remove</button>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </ul>
                                </>
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Tracks