import { useEffect, useState } from 'react'
import { FloatingLabel, Form, Modal, Table } from 'react-bootstrap'
import BreadCrumb from '../../components/elements/BreadCrumb'

import CountryList from '../../data/CountryList'


import useAjax from "../../useAjax";
import Loader from "../../components/elements/loader/Loader";
import Pagination from '../../components/elements/Pagination';

const baseURL = process.env.REACT_APP_ENDPOINT
const tableOptions = {
    'styleClass': 'custom-datatable',
    'sortBy': 'email',
    'sortingIconDown': 'arrow_drop_down',
    'sortingIconUp': 'arrow_drop_up',
    'searchBar': true,
    'searchBarPlaceholder': 'Search',
    'pageNo': 1,
    'perPage': true,
    'perPageLabel': 'Items per page',
    'perPageDefault': 10,
    'perPageOptions': [10, 20, 50],
    'pagination': true,
    'paginationOptions': {
        'styleClass': 'pagination',
        'labelLatest': '',
        'iconLatest': 'New',
        'labelOldest': '',
        'iconOldest': 'Old',
        'labelPrevious': 'Prev',
        'iconPrevious': 'Prev',
        'iconNext': '',
        'labelNext': 'Next'
    }
}
const headers = [
    // {
    //     'label': '#',
    //     'key': '',
    //     'sortable': false
    // },
    {
        'label': 'First Name',
        'key': 'first_name',
        'sortable': false
    }, {
        'label': 'Last Name',
        'key': 'last_name',
        'sortable': false
    }, {
        'label': 'Email',
        'key': 'email',
        'sortable': false
    }, {
        'label': 'Country',
        'key': 'country',
        'sortable': false
    }, {
        'label': 'Affiliation',
        'key': 'affiliation',
        'sortable': false
    }, {
        'label': 'Status',
        'key': 'status',
        'sortable': false
    }, {
        'label': 'Actions',
        'key': '',
        'sortable': false
    },
]
const ManageUsers = () => {

    const [data, setData] = useState([])
    const [editData, setEditData] = useState([])

    const [showProfileEditModal, setShowProfileEditModal] = useState(false);

    const [getUserList, userData, isLoadingUserData] = useAjax('get', `${baseURL}/users`);
    const [updateProfile, profileUpdateSuccess] = useAjax('post', `${baseURL}/users/update`)
    const [getSingleUserDataCall, singleUserData] = useAjax('get')
    const [deleteUserCall, deleteUserCallSuccess] = useAjax('delete');

    const [searchText, setSearchText] = useState(null)
    const [paramSearch, setParamSearch] = useState('')
    const [paginationCount, setPaginationCount] = useState()
    const [totalCount, setTotalCount] = useState()
    const [pageNo, setPageNo] = useState(tableOptions.pageNo)
    const [perPage, setPerPage] = useState(tableOptions.perPageDefault)
    const [sortBy, setSortBy] = useState(tableOptions.sortBy)
    const [orderType, setOrderType] = useState('ASC')
    const [sortOrder, setSortOrder] = useState(tableOptions.sortingIconDown)
    const [sortActive, setSortActive] = useState('')

    const handleShowProfileEditModal = (user_id) => {
        getSingleUserData(user_id)
        setShowProfileEditModal(true)
    }
    const handleCloseProfileEditModal = () => setShowProfileEditModal(false)

    const getSingleUserData = (id) => {
        getSingleUserDataCall({ URL: `${baseURL}/users/${id}` });
    }

    useEffect(() => {
        if (singleUserData?.data.result) {
            const result = singleUserData?.data.result[0];

            setEditData({
                user_id: result.id,
                first_name: result.first_name,
                last_name: result.last_name,
                country: result.country,
                affiliation: result.affiliation
            });
        }
    }, [singleUserData])

    useEffect(() => {
        setData(userData?.result.data);
        setTotalCount(userData?.result.total);
        setPaginationCount(() => {
            return Math.ceil(userData?.result.total / perPage)
        })
    }, [userData]);

    const handleChangeEdit = (e) => {
        let name = e.target.name
        let value = e.target.value

        setEditData((prev) => {
            return {
                ...prev, [name]: value
            }
        })
    }
    const handleSubmitProfileEdit = (e) => {
        e.preventDefault();
        const confirmation = window.confirm("Are you sure to update the User?")
        if (confirmation) {
            updateProfile({ payload: editData });
        }
    }
    const handleDelete = (deleteId) => {
        if (window.confirm("Are you sure to delete this User?")) {
            deleteUserCall({ URL: `${baseURL}/users/${deleteId}` });
        }
    }

    const statusViewer = (val) => {
        if (val === 1) {
            return <span className='badge bg-success'>Active</span>
        } else if (val === 0) {
            return <span className='badge bg-warning'>In-Active</span>
        }
    }

    //Datatabel
    const handelSorting = (key) => {
        setSortBy(key)
        setSortActive(key === sortActive ? null : key)
    }

    const selectedPagination = (pageNumber) => {
        setPageNo(pageNumber)
    }
    
    const handleChangeSearchText = (e) => {
        const searchTerm = e.target.value
        setSearchText(searchTerm)
        if (searchText != null) {
            setParamSearch(`param_search=${e.target.value}&`)
        }

        if (searchTerm.length > 2) {
            getUserList({
                URL: `${baseURL}/users?${paramSearch}order_type=${orderType}&order_by=${sortBy}&per_page=${perPage}&page=${pageNo}`
            })
        } else if (searchTerm.length === 0) {
            getUserList({
                URL: `${baseURL}/users?order_type=${orderType}&order_by=${sortBy}&per_page=${perPage}&page=${pageNo}`
            })
        }
    }

    useEffect(() => {
        /*setHttpSuccessStatus({
            statusCode: 200,
            statusMessage: profileUpdateSuccess?.message
        })*/
        getUserList({
            URL: `${baseURL}/users?${paramSearch}order_type=${orderType}&order_by=${sortBy}&per_page=${perPage}&page=${pageNo}`
        })
        handleCloseProfileEditModal()
    }, [profileUpdateSuccess, deleteUserCallSuccess, perPage, pageNo]);

    return (
        <>

            <BreadCrumb title='All User' from='Settings' to='Manage User' back={true} />
            <div className="card p-3">
                <div className="d-flex justify-content-between">
                    <div>
                        <input type="search" name="table-global" placeholder={tableOptions.searchBarPlaceholder} onChange={handleChangeSearchText} />
                    </div>
                    <div>
                        <select name="h" value={perPage} onChange={(e) => setPerPage(e.target.value)}>
                            {
                                tableOptions.perPageOptions.map((item) => {
                                    return (
                                        <option value={item}>{item}</option>
                                    )
                                })
                            }
                        </select>
                        {tableOptions.perPageLabel}
                    </div>
                </div>
                {
                    isLoadingUserData ? (<Loader />)
                        : (
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        {
                                            headers.map((item) => {
                                                const { label, key, sortable } = item
                                                return (
                                                    sortable ? <th onClick={() => handelSorting(key)} className={`sorting ${key === sortActive ? 'active' : ''}`}>
                                                        {label}
                                                        <span className="material-icons">
                                                            {sortOrder}
                                                        </span>
                                                    </th> : <th >{label}</th>
                                                )
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data && data.map((item, key) => {
                                            const { id, first_name, last_name, email, country, affiliation, status } = item
                                            return (
                                                <tr key={id}>
                                                    {/* <td>{key + 1}</td> */}
                                                    <td>{first_name}</td>
                                                    <td>{last_name}</td>
                                                    <td>{email}</td>
                                                    <td>{country}</td>
                                                    <td>{affiliation}</td>
                                                    <td>{statusViewer(status)}</td>
                                                    <td>
                                                        <button
                                                            className='btn btn-light btn-xs elevated btn-classic me-2 mb-1'
                                                            onClick={() => handleShowProfileEditModal(id)}><span
                                                                className="material-icons text-info">edit</span> Edit
                                                        </button>
                                                        <button
                                                            className='btn btn-light btn-xs elevated btn-classic me-2 mb-1'
                                                            onClick={() => handleDelete(id)}><span
                                                                className="material-icons text-danger">delete</span> Delete
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        )
                }
                <div className="d-flex justify-content-between">
                    <Pagination data={paginationCount} callback={selectedPagination} options={tableOptions.paginationOptions} />
                    <div>
                        Total <strong>{totalCount}</strong> records
                    </div>
                </div>
            </div>
            {/* Edit Profile */}
            <Modal Modal show={showProfileEditModal} onHide={handleCloseProfileEditModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmitProfileEdit}>
                        <div className='mb-3'>
                            <FloatingLabel className='mb-3' controlId="" label="First Name">
                                <Form.Control type="text" placeholder="First Name" name='first_name'
                                    value={editData.first_name} onChange={handleChangeEdit} required />
                            </FloatingLabel>
                            <FloatingLabel className='mb-3' controlId="" label="Last Name">
                                <Form.Control type="text" placeholder="Last Name" name='last_name'
                                    value={editData.last_name} onChange={handleChangeEdit} />
                            </FloatingLabel>
                            <FloatingLabel className='mb-3' controlId="" label="Country">
                                {/* <Form.Control type="text" placeholder="Country" name='country' value={editData.country} onChange={handleChangeEdit} required /> */}
                                <Form.Select name='country' value={editData.country} onChange={handleChangeEdit}
                                    required>
                                    <option>Select</option>
                                    {
                                        CountryList.map((item, key) => {
                                            return <option value={item} key={key}>{item}</option>
                                        })
                                    }
                                </Form.Select>
                            </FloatingLabel>
                            <FloatingLabel className='mb-3' controlId="" label="Affiliation">
                                <Form.Control type="text" placeholder="Affiliation" name='affiliation'
                                    value={editData.affiliation} onChange={handleChangeEdit} required />
                            </FloatingLabel>

                        </div>
                        <div className='text-end'>
                            <button className="btn btn-clear secondary ms-3" onClick={handleCloseProfileEditModal}
                                type='reset'>Cancel
                            </button>
                            <button className="btn btn-clear primary" type='submit'>Submit</button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ManageUsers