import { Link, NavLink } from 'react-router-dom'
import logo from '../../static/images/logo-180-50.png'

import { Container, Nav, Navbar } from "react-bootstrap"
import { useEffect, useState } from 'react'
import Axios from 'axios'
import AlertCustom from '../../components/elements/AlertCustom'

const baseURL = process.env.REACT_APP_ENDPOINT

const CfpList = () => {
    const [data, setData] = useState([])
    const [alertData, setAlertData] = useState({
        'alertShow': false,
        'alertVariant': null,
        'alertMessage': null
    })
    useEffect(()=>{
        if (alertData.alertShow) {
            setTimeout(() => {
                setAlertData({
                    'alertShow': false,
                })
            }, 7000)
        }
    },[alertData])

    useEffect(() => {
        Axios.get(`${baseURL}/site/cfp-list`)
            .then(res => {
                setData(res.data.result)
            })
            .catch(error => {
                setAlertData({
                    'alertShow': true,
                    'alertVariant': 'warning',
                    'alertMessage': error.response.data
                })
            })
    }, [])

    return (
        <>

            <AlertCustom data={alertData} />
            <div className='sticky-top'>
                <Navbar expand="lg" className="navbar-inverse  bg-body-tertiary">
                    <Container>
                        <Link to={"https://easymeet.ws/"} title='EasyMeet'><img src={logo} alt='Logo' /></Link>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse>
                            <Nav className="ms-auto">
                                <NavLink to={'/'} className='btn'>Login</NavLink>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>

            <main className='classicStyle container'>
                <div className="row">
                    {
                        data.map((item) => {
                            const { event_name, acronym_title, city, state, country, venue, start_date, end_date, logo, logo_mime_type, conf_id } = item
                            return (
                                <div className="col-6">
                                    <div className="card bg-fill-light-redish my-3">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="" style={{ width: '100px' }}>
                                                    {
                                                        logo ? <img src={`data:${logo_mime_type};base64,${logo}`} className='card-logo thumbnail elevated' alt='logo' /> : <div className='defaultLogo thumbnail elevated'>{acronym_title}</div>
                                                    }
                                                </div>
                                                <div className="col">
                                                    <h2>{event_name}</h2>
                                                    <h3>{acronym_title}</h3>
                                                    <address>
                                                        <span className='d-block'>{venue}</span>
                                                        <span className='d-block'>{city}</span>
                                                        <span className='d-block'>{state}</span>
                                                        <span className='d-block'>{country}</span>
                                                    </address>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card-footer d-flex justify-content-between'>
                                            <div>
                                                From - {start_date} To - {end_date}
                                            </div>
                                            <div>
                                                <Link className="btn btn-primary btn-sm" target='_blank' to={`/cfp/${conf_id}`}>CFP</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            )
                        })
                    }
                </div>
            </main>

            <footer className="bg-fill-light-redish py-3 text-center">
                Copyright © 2023 Easy Meet. All rights reserved
            </footer>
        </>
    )
}

export default CfpList