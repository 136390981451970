import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../components/elements/BreadCrumb'
import AlertCustom from '../../components/elements/AlertCustom'
import Axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { FloatingLabel, Form, Modal, Table, Card } from 'react-bootstrap'
import FinalDecision from '../../data/FinalDecision'
import Loader from "../../components/elements/loader/Loader";

const baseURL = process.env.REACT_APP_ENDPOINT

const Papers = () => {
    //Define
    let tokenStr = localStorage.getItem('access_token')
    const navigate = useNavigate()
    const detailsId = useParams()
    const [data, setData] = useState({})
    const [allPaper, setAlPaper] = useState([])
    const [alertData, setAlertData] = useState({
        'alertShow': false,
        'alertVariant': null,
        'alertMessage': null
    })
    const [showReviewModal, setShowReviewModal] = useState(false)
    const [dataReview, setDataReview] = useState({
        'decision': 'No Decision',
        'final_decision_remark': ''
    })
    const [paperId, setPaperId] = useState(null)
    const [singlePaper, setSinglePaper] = useState([])
    const [singlePaperReviewData, setSinglePaperReviewData] = useState([])

    //Action
    useEffect(() => {
        if (alertData.alertShow) {
            setTimeout(() => {
                setAlertData({
                    'alertShow': false,
                })
            }, 7000)
        }
    }, [alertData])

    useEffect(() => {
        Axios.get(`${baseURL}/site/conf/short/${detailsId.id}`)
            .then(res => {
                setData(res.data.result[0])
            })
            .catch(
                error => {
                    if (error.response.status === 401) {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'danger',
                            'alertMessage': 'You are not authorized to access this content.You will be logged out. Please login again!'
                        })
                        setTimeout(() => {
                            navigate('/')
                        }, 6000)
                    } else {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'warning',
                            'alertMessage': error.response.data
                        })
                    }
                }
            )

        Axios.get(`${baseURL}/paper/all/${detailsId.id}/0`, { headers: { "Authorization": `Bearer ${tokenStr}` } })
            .then(res => {
                setAlPaper(res.data.result.data)
            })
            .catch(
                error => {
                    if (error.response.status === 401) {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'danger',
                            'alertMessage': 'You are not authorized to access this content.You will be logged out. Please login again!'
                        })
                        setTimeout(() => {
                            navigate('/')
                        }, 6000)
                    } else {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'warning',
                            'alertMessage': error.response.data
                        })
                    }
                }
            )
    }, [])


    const getSinglePaper = (id) => {
        Axios.get(`${baseURL}/paper/${id}`, { headers: { "Authorization": `Bearer ${tokenStr}` } })
            .then(res => {
                setSinglePaper(res.data.result)
                setSinglePaperReviewData(res.data.result.paper_reviewer_data)
            })
            .catch(
                error => {
                    if (error.response.status === 401) {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'danger',
                            'alertMessage': 'You are not authorized to access this content.You will be logged out. Please login again!'
                        })
                        setTimeout(() => {
                            navigate('/')
                        }, 6000)
                    } else {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'warning',
                            'alertMessage': error.response.data
                        })
                    }
                }
            )
    }

    const handleCloseReviewModal = () => setShowReviewModal(false);
    const handleShowReviewModal = (id) => {
        setPaperId(id)
        getSinglePaper(id)
        setShowReviewModal(true)
    };

    const handleChangeReview = (e) => {
        let name = e.target.name
        let value = e.target.value

        setDataReview((prev) => {
            return {
                ...prev, [name]: value
            }
        })
    }
    const handleSubmitReview = (e) => {
        e.preventDefault()
        let name = e.target.name
        let value = e.target.value

        setDataReview((prev) => {
            return {
                ...prev, [name]: value
            }
        })

        const confirmation = window.confirm("Are you sure to Submit this Final Review?")
        if (confirmation) {
            console.log(dataReview)
            Axios.post(`${baseURL}/paper/final-review/${paperId}`, dataReview, { headers: { "Authorization": `Bearer ${tokenStr}` } })
                .then((res) => {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'success',
                        'alertMessage': res.data.message
                    })
                    window.location.reload()
                })
                .catch(
                    error => {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'danger',
                            'alertMessage': error.response.data
                        })
                    }
                )
        }
    }
    return (
        <>
            <AlertCustom data={alertData} />
            <BreadCrumb title={`All Papers - ${data.acronym_title}`} from='Conference' to='Papers' back={true} />
            <div className="card elevated p-3">
                {
                    allPaper.length > 0 ? (
                        <Table striped bordered hover style={{fontSize:'12px'}}>
                            <thead>
                                <tr className=''>
                                    <th>PaperID</th>
                                    <th>Download</th>
                                    <th>Paper Title</th>
                                    <th>Authors</th>
                                    <th>Track</th>
                                    <th>Reviewers</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    allPaper.length > 0 ?
                                        allPaper.map((item) => {
                                            const { id, paper_title, paper_sequence, paper_file, paper_file_mime_type, paper_author_data, track_name, paper_reviewer_data } = item
                                            return (

                                                <tr key={id}>
                                                    <td><span className='badge bg-primary'>{paper_sequence}</span></td>
                                                    <td>
                                                        {
                                                            paper_file ? <a className='btn btn-primary btn-xs' href={`data:${paper_file_mime_type};base64,${paper_file}`} download={paper_sequence}><span className="material-icons">file_download</span> </a> : ''
                                                        }
                                                    </td>
                                                    <td>{paper_title}</td>
                                                    <td>
                                                        {
                                                            paper_author_data.length > 0 ?
                                                                paper_author_data.map((item) => {
                                                                    const { first_name, last_name, email } = item
                                                                    return (
                                                                        <div className='mb-3'>{first_name} {last_name} <br />{email}</div>
                                                                    )
                                                                })
                                                                : ''
                                                        }
                                                    </td>
                                                    <td>{track_name}</td>
                                                    <td>
                                                        {
                                                            paper_reviewer_data.length > 0 ?
                                                                paper_reviewer_data.map((item) => {
                                                                    const { first_name, last_name, email } = item
                                                                    return (
                                                                        <div className='mb-3'>{first_name} {last_name} <br />{email}</div>
                                                                    )
                                                                })
                                                                : ''
                                                        }
                                                    </td>
                                                    <td>
                                                        <button className='btn btn-light elevated btn-xs btn-classic mb-2 me-1' onClick={() => handleShowReviewModal(id)} title='Final Review'><span className="material-icons text-warning">reviews</span> Final Review</button>
                                                    </td>
                                                </tr>
                                            )
                                        }) : "No record found"
                                    // <div className='card bg-fill-light-redish'><div className='card-body'><span class='material-icons text-danger'>priority_high</span> No paper is submitted yet.</div></div>
                                }
                            </tbody>
                        </Table>
                    ) : (<Loader />)
                }
            </div>
            <Modal show={showReviewModal} onHide={handleCloseReviewModal} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title><span className='badge bg-primary'>{singlePaper.paper_sequence}</span> {singlePaper.paper_title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <div className="row">
                        <div className="col-6"> */}
                            <h3>All Reviews</h3>
                            <hr />
                            <table className='table table-bordered ' style={{fontSize:'12px'}}>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Reviewer Evaluation</th>
                                        <th>Reviewer Confidence</th>
                                        <th>Reviewer Remarks</th>
                                        <th>Reviewer Confidential Remarks</th>
                                        <th>Reviewed At</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    
                            {
                                singlePaperReviewData.map((item) => {
                                    const { first_name, last_name, reviewed_at, reviewer_evaluation, reviewer_confidence, reviewer_remarks, reviewer_confidential_remarks } = item
                                    return (
                                        // <Card className='p-2 elevated mb-2'>
                                        //     <div className="grid-container">
                                        //         <div className='grid-item'><b>Name</b></div>
                                        //         <div className='grid-item'>{first_name} {last_name}</div>
                                        //         <div className='grid-item'><b>Reviewer Evaluation</b></div>
                                        //         <div className='grid-item'>{reviewer_evaluation}</div>
                                        //         <div className='grid-item'><b>Reviewer Confidence</b></div>
                                        //         <div className='grid-item'>{reviewer_confidence}</div>
                                        //         <div className='grid-item'><b>Reviewer Remarks</b></div>
                                        //         <div className='grid-item'>{reviewer_remarks}</div>
                                        //         <div className='grid-item'><b>Reviewer Confidential Remarks</b></div>
                                        //         <div className='grid-item'>{reviewer_confidential_remarks}</div>
                                        //         <div className='grid-item'><b>Reviewed At</b></div>
                                        //         <div className='grid-item'>{reviewed_at}</div>
                                        //     </div>
                                        // </Card>
                                        <tr>
                                            <td>{first_name} {last_name}</td>
                                            <td>{reviewer_evaluation}</td>
                                            <td>{reviewer_confidence}</td>
                                            <td>{reviewer_remarks}</td>
                                            <td>{reviewer_confidential_remarks}</td>
                                            <td>{reviewed_at}</td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                        {/* </div>
                        <div className="col-6"> */}
                            <h3>Add Final Review</h3>
                            <hr />
                            <Form onSubmit={handleSubmitReview}>
                                <div className='mb-3'>
                                    <FloatingLabel className='' controlId="decision" label="Decision" >
                                        <Form.Select name='decision' value={dataReview.decision} onChange={handleChangeReview}>
                                            {
                                                FinalDecision.map((item) => {
                                                    const { value, label } = item
                                                    return <option value={value}>{label}</option>
                                                })
                                            }
                                        </Form.Select>
                                    </FloatingLabel>
                                </div>
                                <div className='mb-3'>
                                    <FloatingLabel className='required' controlId="final_decision_remark" label="Final Decision Remark">
                                        <Form.Control
                                            as="textarea"
                                            placeholder="Your Final Remark"
                                            name='final_decision_remark'
                                            value={dataReview.final_decision_remark}
                                            onChange={handleChangeReview}
                                            style={{ height: '100px' }}
                                            required={true}
                                        />
                                    </FloatingLabel>
                                </div>

                                <div className='text-end'>
                                    <button className="btn btn-clear secondary ms-3" onClick={handleCloseReviewModal} type='reset'>Cancel</button>
                                    <button className="btn btn-clear priamry" type='submit'>Submit</button>
                                </div>
                            </Form>
                        {/* </div>
                    </div> */}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Papers