
const OrganizingCommitteeType = [
    {
        'value': 1,
        'label': "General Chairs"
    }, {
        'value': 2,
        'label': "Program Chairs"
    }, {
        'value': 3,
        'label': "Organizing Secretaries"
    }, {
        'value': 4,
        'label': "Publication Chairs"
    }, {
        'value': 5,
        'label': "Finance Chairs"
    }, {
        'value': 6,
        'label': "Web Chairs"
    }, {
        'value': 7,
        'label': "Media Chairs"
    }, {
        'value': 8,
        'label': "Special Session Chairs"
    }, {
        'value': 9,
        'label': "Registration Chair"
    }, {
        'value': 10,
        'label': "Publicity Chair"
    }, {
        'value': 11,
        'label': "Panel Chairs"
    }, {
        'value': 12,
        'label': "Women in Engineering Chairs"
    }, {
        'value': 13,
        'label': "Steering Committee Chair"
    }, {
        'value': 14,
        'label': "IEEE Liaison Chairs"
    }, {
        'value': 15,
        'label': "Student Research Forum Chairs"
    }, {
        'value': 16,
        'label': "Research Demo Session  Chairs"
    },
]
export default OrganizingCommitteeType