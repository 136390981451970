import Axios from 'axios'
import loginLogo from '../../static/images/logo-180-50.png'
import React, { useEffect, useState } from 'react'
import { Card, CardBody, FloatingLabel, Form, FormGroup } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import AlertCustom from '../../components/elements/AlertCustom'
import styles from './login.module.css';

import CountryList from '../../data/CountryList'
const baseURL = process.env.REACT_APP_ENDPOINT;

const Signup = () => {
    const navigate = useNavigate()
    const [alertData, setAlertData] = useState({
        'alertShow': false,
        'alertVariant': null,
        'alertMessage': null
    })
    const [data, setData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: ''
    })

    useEffect(() => {
        if (alertData.alertShow) {
            setTimeout(() => {
                setAlertData({
                    'alertShow': false,
                    'alertVariant': '',
                    'alertMessage': null
                })
            }, 7000)
        }
    }, [alertData])

    const handleChange = (e) => {
        setData((prev) => {
            const name = e.target.name
            const value = e.target.value
            return {
                ...prev, [name]: value
            }
        })
    }


    const formSubmit = (e) => {
        e.preventDefault()

        Axios.post(`${baseURL}/register`, data)
            .then((res) => {
                if (res.data.success === false) {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'warning',
                        'alertMessage': res.data.message
                    })
                } else {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'success',
                        'alertMessage': res.data.message
                    })

                    setTimeout(() => {
                        navigate('/')
                    }, 3000)
                }
            })
            .catch(
                error => {
                    let statuses = error.response.status
                    if (statuses === 422) {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'danger',
                            'alertMessage': error.response.data.result.email[0]
                        })
                    } else {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'danger',
                            'alertMessage': error.response.data.message
                        })
                    }
                }
            )

    }
    return (
        <>
            <AlertCustom data={alertData} />
            <div className={styles.loginPage}>
                <div className={styles.leftPanel}>
                    <div>
                        <svg width="301" height="77" viewBox="0 0 301 77" xmlns="http://www.w3.org/2000/svg">
                            <text id="EASYMEET" x="3" y="74" font-family="Impact" font-size="74" font-weight="800" fill="#ffffff">EASYMEET</text>
                        </svg>
                        <div className={styles.leftPanelMessage}>The easiest platform for creating conferences.</div>
                    </div>

                    <img className={styles.leftPanelOverlayImg} src="/images/1206.jpg" alt={''} />


                </div>
                <div className={styles.rightPanel}>
                    <div className='d-flex justify-content-center align-items-center login'>
                        <Card className='login-card '>
                            <CardBody>
                                <Form onSubmit={formSubmit} className='w280'>
                                    <h1 className='text-center'>Register to EasyMeet</h1>
                                    {/* <Link to={'/'}><img src={loginLogo} className='d-block mx-auto' alt='Easy Meet' /></Link> */}
                                    <div className="row mt-4">
                                        <div className="col-sm-12">
                                            <FormGroup className="mb-3">
                                                <FloatingLabel controlId="first_name" label="First Name">
                                                    <Form.Control type="text" name='first_name' placeholder="First Name" value={data.first_name} onChange={handleChange} required />
                                                    <span></span>
                                                </FloatingLabel>
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-12">
                                            <FormGroup className="mb-3">
                                                <FloatingLabel controlId="last_name" label="Last Name">
                                                    <Form.Control type="text" name='last_name' placeholder="Last Name" value={data.last_name} onChange={handleChange} required />
                                                    <span></span>
                                                </FloatingLabel>
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-12">
                                            <FormGroup className="mb-3">
                                                <FloatingLabel controlId="email" label="Email">
                                                    <Form.Control type="email" name='email' placeholder="Email" value={data.email} onChange={handleChange} required />
                                                    <span></span>
                                                </FloatingLabel>
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6">
                                            <FormGroup className="mb-3">
                                                <FloatingLabel controlId="country" label="Country">
                                                    <Form.Select name='country' value={data.country} onChange={handleChange} required>
                                                        <option>Select</option>
                                                        {
                                                            CountryList.map((item, key) => {
                                                                return <option value={item} key={key}>{item}</option>
                                                            })
                                                        }
                                                    </Form.Select>
                                                    <span></span>
                                                </FloatingLabel>
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6">
                                            <FormGroup className="mb-3">
                                                <FloatingLabel controlId="affiliation" label="Affiliation">
                                                    <Form.Control type="text" name='affiliation' placeholder="Affiliation" value={data.affiliation} onChange={handleChange} required />
                                                    <span></span>
                                                </FloatingLabel>
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-12">
                                            <FormGroup className="mb-3">
                                                <FloatingLabel controlId="password" label="Preferred Password" >
                                                    <Form.Control type="password" name='password' placeholder="Preferred Password" value={data.password} onChange={handleChange} required />
                                                    <span></span>
                                                </FloatingLabel>
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <input type="submit" value="Signup" className='btn btn-primary w-100' />
                                    <p className='my-3 text-center'>Already Registered <Link to={'/'}>Login</Link></p>
                                </Form>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Signup